export default {
  "clean": {
    nome: 'Layout clean',
    valor: 'clean',
  },
  "default": {
    nome: 'Layout padrão',
    valor: 'default',
  },
  "sem_capa": {
    nome: 'Layout sem capa',
    valor: 'sem_capa',
  },
};
