<template>
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <h2 v-if="user">{{ user?.name }}</h2>
      <div v-if="modal" class="modal-dialog modal-dialog-centered modal-overlay">
        <div class="modal-content">
          <div class="modal-header justify-content-between d-flex justify-content-between">
            <h3>Feliz aniversario 🎂</h3>
            <button class="Modalclose" type="button" @click="closeModal">
              <font-awesome-icon icon="circle-xmark" />
            </button>
          </div>
          <div class="bordaCiano modal-body mt-3 px-2 py-3">
            <p class="text-start">
              É com grande alegria que celebramos mais um ano de sucesso ao seu lado. Que este novo ciclo traga ainda mais conquistas, 
              crescimento e momentos especiais. Agradecemos a confiança e parceria de sempre, e estamos ansiosos para continuar construindo histórias de sucesso juntos.<br>
              Desejamos a vocês um ano cheio de realizações e prosperidade!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-end">
      <div 
        v-if="!user?.imagem_perfil" 
        class="user d-flex align-items-center"
      >
        <font-awesome-icon 
          icon="user"
        />
      </div>
      <div 
        v-else 
        class="pb-3 d-flex align-items-center"
      >
        <img 
          class="user"
          :src="user.imagem_perfil"
        >
      </div>
      <CDropdown>
        <CDropdownToggle class="user_configuracoes p-0">
          <font-awesome-icon icon="gear" />
        </CDropdownToggle>
        <CDropdownMenu class="menu">
          <CDropdownItem
            v-if="!isSuperUser"  
          >
            <router-link to="/financeiro" class="dropdown-item d-flex align-items-center">
              <font-awesome-icon icon="hand-holding-dollar" class="me-2" /> Financeiro
            </router-link>
          </CDropdownItem>
          <CDropdownItem>
            <router-link to="/perfil" class="dropdown-item d-flex align-items-center">
              <font-awesome-icon icon="user-gear" class="me-2" /> Perfil
            </router-link>
          </CDropdownItem>
          <CDropdownItem @click="logout">
            <font-awesome-icon icon="right-from-bracket" class="me-2" /> Sair
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/store/auth.store';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const authStore = useAuthStore();
const router = useRouter();

const { user, isSuperUser} = storeToRefs(authStore)
const modal = ref(false)

onMounted(() => {
  if (user.value) {
    const today = new Date();
    const userBirthday = new Date(user.value.nascimento);
    const localStorageKey = `birthdayModal-${today.getFullYear()}`;
    const modalDismissed = localStorage.getItem(localStorageKey);

    if (
      today.getDate() === userBirthday.getDate() &&
      today.getMonth() === userBirthday.getMonth() &&
      !modalDismissed
    ) {
      modal.value = true;
    }
  }
});

function closeModal() {
  const today = new Date();
  const localStorageKey = `birthdayModal-${today.getFullYear()}`;
  localStorage.setItem(localStorageKey, 'true');
  modal.value = false;
}

function logout() {
  authStore.logout();
  router.push('/login');
}
</script>

<style scoped>
.user{
  width: 80px;
  height: 80px;
  background-color: var(--cinza);
  border-radius: 50%;
  position: relative;
  left: 15px;
}

.user_configuracoes{
  background-color: transparent;
  border: none;
  color: #5F5F5F;
  font-size: 38px
}

.fa-user {
  color: var(--branco);
  font-size: 32px;
}

.menu a {
  width: 180px;
  padding: 2px 0;
}

.dropdown-toggle::after {
  display: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  width: 625px;
  max-width: 100%;
  position: relative;
}
</style>