<template>
  <div class="card-branco">
    <router-link
      to="/planos"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <h1 class="mb-2">
      {{ route?.meta?.título }}
    </h1>

    <form
      @submit.prevent="onSubmitPlano"
    >
      <div class="d-flex gap-3 w-100 mb-2 align-items-center">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="nome" 
            :schema="schema"
            :required="true"
          />
          <Field
            name="nome"
            placeholder="Nome do plano"
            type="text"
            class="inputtext"
            :class="{ 'error': errors.nome }"
          />
          <ErrorMessage
            class="error-msg"
            name="nome"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="tipo_plano" 
            :schema="schema"
          />
          <Field 
            name="tipo_plano" 
            as="select"
            class="inputtext"
            :class="{ 'error': errors.tipo_plano }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(tipoPlano)"
              :key="item.nome"
              :value="item.nome"
            >
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage 
            class="error-msg"
            name="tipo_plano"
          />
        </div>

        <div class="d-flex gap-3 w-75 ">
          <div class="d-flex">
            <Field
              name="trial"
              type="checkbox"
              :value="true"
              class="inputcheckbox"
            />
            <LabelFromYup
              name="trial"
              :schema="schema"
              class="ps-2 pb-0"
            />
          </div>
          <div class="d-flex">
            <Field
              v-model="recorrente"
              name="recorrente"
              type="checkbox"
              :value="true"
              class="inputcheckbox"
            />
            <LabelFromYup
              name="recorrente"
              :schema="schema"
              class="ps-2 pb-0"
            />
          </div>
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2 align-items-center">
        <div class="mb-3 w-100">
          <div class="d-flex align-items-center">
            <LabelFromYup
              :schema="schema"
              name="ordem"
              class="label"
            />
            <CTooltip
              placement="top"
              content="Este campo além de definir a ordem de Exibição do Plano, define também para quais planos pode-se fazer o Upgrade. Exemplo:
              Clientes cujo plano atual tenha ordem 3 podem fazer upgrade somente para os planos que tiverem ordem maior que 3">
              <template #toggler="{ id, on }">
                <font-awesome-icon 
                  :aria-describedby="id"
                  icon="circle-info" 
                  v-on="on" 
                />
              </template>
            </CTooltip>
          </div>
          <Field
            name="ordem"
            type="number"
            min="0"
            class="inputtext"
            :class="{ error: errors.ordem }"
          />
          <ErrorMessage
            name="ordem"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="valor_original" 
            :schema="schema"
          />
          <FloatMaskInput
            v-model="valor_original"
            :value="values.valor_original"
            name="valor_original" 
            type="text"
            placeholder="Valor divulgação" 
            class="inputtext"
            :class="{ error: errors.valor_original }"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg"
            name="valor_original"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="valor" 
            :schema="schema"
          />
          <FloatMaskInput
            v-model="valor"
            :value="values.valor"
            name="valor" 
            type="text"
            placeholder="Valor divulgação" 
            class="inputtext"
            :class="{ error: errors.valor }"
            converter-para="string"
          />
          <ErrorMessage 
            class="error-msg"
            name="valor"
          />
        </div>

        <div class="d-flex w-75">
          <Field
            name="preco_sob_consulta"
            type="checkbox"
            :value="true"
            class="inputcheckbox"
          />
          <LabelFromYup
            name="preco_sob_consulta"
            :schema="schema"
            class="ps-2 pb-0"
          />
        </div>
      </div>

      <label style="color: var(--roxo)">
        <strong>Permissões</strong>
      </label>
      <div class="d-flex gap-3 w-100 mb-2 align-items-center">
        <div class="d-flex gap-3 mb-3">
          <div>
            <div class="d-flex mb-2">
              <Field
                name="permite_logo_propria_pdf"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="permite_logo_propria_pdf"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2 align-items-start">
              <Field
                name="permite_cor_rodape_pdf"
                type="checkbox"
                :value="true"
                class="inputcheckbox mt-1"
              />
              <LabelFromYup
                name="permite_cor_rodape_pdf"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2 align-items-start">
              <Field
                name="permite_analise_valor_percepcao"
                type="checkbox"
                :value="true"
                class="inputcheckbox mt-1"
              />
              <LabelFromYup
                name="permite_analise_valor_percepcao"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2">
              <Field
                name="inserir_amostras"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="inserir_amostras"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2">
              <Field
                name="acc_pai_gerencia"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="acc_pai_gerencia"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
          </div>

          <div>
            <div class="d-flex mb-2">
              <Field
                name="permite_assinatura_pdf"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="permite_assinatura_pdf"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2">
              <Field
                name="permite_impressao_data_pdf"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="permite_impressao_data_pdf"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2 align-items-start">
              <Field
                name="inserir_marca_dagua"
                type="checkbox"
                :value="true"
                class="inputcheckbox mt-1"
              />
              <LabelFromYup
                name="inserir_marca_dagua"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2">
              <Field
                name="valor_divulgacao"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="valor_divulgacao"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2">
              <Field
                name="import_kendo"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="import_kendo"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
          </div>

          <div>
            <div class="d-flex mb-2">
              <Field
                name="permite_envio_pdf_por_email"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="permite_envio_pdf_por_email"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2">
              <Field
                name="imprimir_links"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="imprimir_links"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2 align-items-start">
              <Field
                name="permite_analise_descricao_imovel"
                type="checkbox"
                :value="true"
                class="inputcheckbox mt-1"
              />
              <LabelFromYup
                name="permite_analise_descricao_imovel"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2">
              <Field
                name="historico_imobiliaria"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="historico_imobiliaria"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
            <div class="d-flex mb-2">
              <Field
                name="imoveis_realizados"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="imoveis_realizados"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>
          </div>
        </div>
      </div>

      <label style="color: var(--roxo)">
        <strong>Limites por quantidade</strong>
      </label>
      <div class="d-flex gap-3 w-100 mb-2 align-items-center">
        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="quantidade_avaliacoes"
            class="label"
          />
          <Field
            name="quantidade_avaliacoes"
            type="number"
            min="0"
            class="inputtext"
            :class="{ error: errors.quantidade_avaliacoes }"
          />
          <ErrorMessage
            name="quantidade_avaliacoes"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="quantidade_laudos"
            class="label"
          />
          <Field
            name="quantidade_laudos"
            type="number"
            min="0"
            class="inputtext"
            :class="{ error: errors.quantidade_laudos }"
          />
          <ErrorMessage
            name="quantidade_laudos"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="quantidade_opinioes"
            class="label"
          />
          <Field
            name="quantidade_opinioes"
            type="number"
            min="0"
            class="inputtext"
            :class="{ error: errors.quantidade_opinioes }"
          />
          <ErrorMessage
            name="quantidade_opinioes"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="quantidade_pic"
            class="label"
          />
          <Field
            name="quantidade_pic"
            type="number"
            min="0"
            class="inputtext"
            :class="{ error: errors.quantidade_pic }"
          />
          <ErrorMessage
            name="quantidade_pic"
            class="error-msg"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2 align-items-center">
        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="quantidade_busca_engenheiro"
            class="label"
          />
          <Field
            name="quantidade_busca_engenheiro"
            type="number"
            min="0"
            class="inputtext"
            :class="{ error: errors.quantidade_busca_engenheiro }"
          />
          <ErrorMessage
            name="quantidade_busca_engenheiro"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="quantidade_usuarios"
            class="label"
          />
          <Field
            name="quantidade_usuarios"
            type="number"
            min="0"
            class="inputtext"
            :class="{ error: errors.quantidade_usuarios }"
          />
          <ErrorMessage
            name="quantidade_usuarios"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            :schema="schema"
            name="periodo_minimo"
            class="label"
          />
          <Field
            name="periodo_minimo"
            type="number"
            min="0"
            class="inputtext"
            :class="{ error: errors.periodo_minimo }"
          />
          <ErrorMessage
            name="periodo_minimo"
            class="error-msg"
          />
        </div>
      </div>

      <div class="w-100">
        <LabelFromYup
          name="descricao"
          :schema="schema"
        />
        <Field
          v-focus
          name="descricao"
          as="textarea"
          class="inputtext"
          :class="{ error: errors.descricao }"
        />
        <ErrorMessage
          class="error-msg mb1"
          name="descricao"
        />
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          salvar
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import FloatMaskInput from '@/components/FloatMaskInput.vue';
import { plano as schema } from '@/consts/formSchema';
import tipoPlano from '@/consts/tipoPlano';
import { useAlertStore } from '@/store/alert.store';
import { usePlanoStore } from '@/store/plano.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const alertStore = useAlertStore();
const planoStore = usePlanoStore()

const { emFoco } = storeToRefs(planoStore);

const recorrente = ref(true);
const router = useRouter();
const route = useRoute();
const props = defineProps({
  planoId: {
    type: Number,
    default: 0,
  },
});

const {
  errors, resetForm, isSubmitting, handleSubmit, values
} = useForm({
  initialValues: props.planoId ? emFoco.value : {},
  validationSchema: schema,
});

const onSubmitPlano = handleSubmit(async (values) => {
  try{
    const payload ={
      ...values,
    }
      
    const msg = props.planoId
      ? 'Dados salvos com sucesso!'
      : 'Item adicionado com sucesso!';

    const resposta = props.planoId
       ? await planoStore.salvarItem(payload, props.planoId)
       : await planoStore.salvarItem(payload);  

    if (resposta) {
        alertStore.success(msg);
        planoStore.$reset();
        router.push({ name: "planos" });
      }
  } catch (error) {
    alertStore.error(error);
  }
})

async function iniciar() {
  if (props.planoId) {
    await planoStore.buscarItem(props.planoId);
  }
}
iniciar()


watch(emFoco, (novoValor) => {
  resetForm({ values: novoValor });
});
</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}

.fa-file-lines {
  color: var(--branco);
  font-size: 68px;
}

.imagem-imovel img{
  max-width: 250px;
  border-radius: 18px;
  width: 250px;
}
</style>