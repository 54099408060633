<template>
  <div>
    <DashboardAdm v-if="isSuperUser" />
    <DashboardCliente v-else />
  </div>
</template>

<script setup>
import { useAuthStore } from '@/store/auth.store';
import { storeToRefs } from 'pinia';
import DashboardAdm from './DashboardAdm.vue';
import DashboardCliente from './DashboardCliente.vue';

const authStore = useAuthStore();
const { isSuperUser, isCliente } = storeToRefs(authStore)


</script>

<style scoped>

</style>