<template>
  <div class="avalionLogin">
    <div class="avalionFormLogin">
      <img 
        src="@/assets/imagens/avalionLogo.jpg"
        alt="Logo avalion"
        class="avalionLogo"
      >
      <div class="d-flex gap-3">
        <Form
          v-slot="{ errors, isSubmitting }"
          :validation-schema="schema"
          @submit="onSubmitCadastro"
        >
          <div class="form-group w-100 my-2">
            <label>
              <Field
                name="tipo_pessoa"
                type="radio"
                value="Pessoa Física"
                class="inputcheckbox"
              />
              <span class="ps-2">Pessoa Física (Corretor)</span>
            </label>
            <label>
              <Field
                name="tipo_pessoa"
                type="radio"
                value="Pessoa Jurídica"
                class="inputcheckbox"
              />
              <span class="ps-2">Pessoa Jurídica(Imobiliária)</span>
            </label>
          </div>

          <div class="mb-2 w-100">
            <LabelFromYup 
              name="name" 
              :schema="schema"
              :required="true"
            />
            <Field
              name="name"
              placeholder="Seu nome"
              type="text"
              class="inputtext mb-2"
              :class="{ 'error': errors.name }"
            />
            <ErrorMessage
              class="error-msg"
              name="name"
            />
          </div>

          <div class="d-flex gap-3 w-100">
            <div class="mb-2 w-100">
              <LabelFromYup 
                name="email" 
                :schema="schema"
                :required="true"
              />
              <Field 
                name="email"
                placeholder="seu_email@provedor.com" 
                type="text" 
                class="inputtext"
                :class="{ 'error': errors.email }"
              />
              <ErrorMessage 
                class="error-msg" 
                name="email"
              />
            </div>

            <div class="mb-2 w-100">
              <LabelFromYup 
                name="telefone_celular" 
                :schema="schema"
                :required="true"
              />
              <Field 
                v-maska
                name="telefone_celular"
                type="text"
                placeholder="(00) 00000-0000"
                class="inputtext light mb-2"
                maxlength="15"
                data-maska="(##) #####-####"
                :class="{ 'error': errors.telefone_celular }"
              />
              <ErrorMessage
                class="error-msg"
                name="telefone_celular"
              />
            </div>
          </div>

          <div class="d-flex w-100 gap-3 mb-3">
            <div class="mb-2 d-flex align-items-center">
              <div>
                <LabelFromYup 
                  name="cep"
                  :schema="schema"
                  :required="true"
                />
                <Field
                  v-model="cep"
                  v-maska
                  name="cep"
                  type="text"
                  placeholder="CEP"
                  class="inputtext"
                  maxlength="9"
                  data-maska="#####-###"
                  :class="{ 'error': errors.cep }"
                  @focusout="getCep"
                />
                <ErrorMessage
                  class="error-msg"
                  name="cep"
                />
              </div>
              <span v-if="chamadasPendentes.emFoco">
                <font-awesome-icon
                  icon="spinner" 
                  class="spinner mt-3"
                />
              </span>
            </div>

            <div class="mb-2 w-75">
              <LabelFromYup
                name="endereco" 
                :schema="schema"
                :required="true"
              />
              <Field
                v-model="lista.logradouro"
                name="endereco" 
                type="text" 
                placeholder="Rua" 
                class="inputtext"
                :class="{ 'error': errors.endereco }"
              />
              <ErrorMessage 
                class="error-msg"
                name="endereco"
              />
            </div>
          </div>

          <div class="d-flex gap-3 w-100 mb-3">
            <div class="mb-2 w-100">
              <LabelFromYup 
                name="numero" 
                :schema="schema"
                :required="true"
              />
              <Field 
                name="numero" 
                type="text" 
                placeholder="Número" 
                class="inputtext"
                :class="{ 'error': errors.numero }"
              />
              <ErrorMessage 
                class="error-msg" 
                name="numero"
              />
            </div>

            <div class="w-100 w-75">
              <LabelFromYup 
                name="bairro" 
                :schema="schema"
                :required="true"
              />
              <Field
                v-model="lista.bairro"
                name="bairro" 
                type="text" 
                placeholder="Bairro" 
                class="inputtext"
                :class="{ 'error': errors.bairro }"
              />
              <ErrorMessage 
                class="error-msg"
                name="bairro"
              />
            </div>
          </div>

          <div class="d-flex gap-3 w-100">
            <div class="mb-3 w-100">
              <LabelFromYup 
                name="cidade"
                :schema="schema"
                :required="true"
              />
              <Field
                v-model="lista.localidade"
                name="cidade" 
                type="text" 
                placeholder="cidade" 
                class="inputtext"
                :class="{ 'error': errors.cidade }"
              />
              <ErrorMessage 
                class="error-msg" 
                name="cidade"
              />
            </div>

            <div class="mb-3 w-100">
              <LabelFromYup 
                name="estado" 
                :schema="schema"
                :required="true"
              />
              <Field
                v-model="lista.uf"
                name="estado" 
                type="text" 
                placeholder="Estado" 
                class="inputtext"
                :class="{ 'error': errors.estado }"
              />
              <ErrorMessage 
                class="error-msg"
                name="estado"
              />
            </div>
          </div>
          
          <div class="d-flex gap-3 w-100 mb-2">
            <div class="mb-3 w-100">
              <LabelFromYup 
                name="cpf" 
                :schema="schema"
                :required="true"
              />
              <Field 
                v-maska
                name="cpf"
                type="text"
                placeholder="CPF/CNPJ"
                class="inputtext"
                maxlength="18"
                data-maska="['###.###.###-##', '##.###.###/####-##']"
                :class="{ 'error': errors.cpf }"
              />
              <ErrorMessage
                class="error-msg"
                name="cpf"
              />
            </div>

            <div class="mb-2 w-100">
              <LabelFromYup 
                name="complemento" 
                :schema="schema"
              />
              <Field 
                name="complemento"
                type="text" 
                placeholder="Complemento" 
                class="inputtext light mb-2"
                :class="{ 'error': errors.complemento }"
              />
              <ErrorMessage 
                class="error-msg" 
                name="complemento"
              />
            </div>
          </div>

          <div class="d-flex gap-3 w-100 mb-2">
            <div class="mb-2">
              <LabelFromYup 
                name="password" 
                :schema="schema"
                :required="true"
              />
              <Field
                name="password" 
                placeholder="*******" 
                type="password" 
                class="inputtext"
                :class="{ 'error': errors.password }"  
              />
              <ErrorMessage 
                class="error-msg" 
                name="password"
              />
            </div>

            <div class="mb-2">
              <LabelFromYup 
                name="password_confirmation" 
                :schema="schema"
                :required="true"
              />
              <Field
                name="password_confirmation" 
                placeholder="*******" 
                type="password" 
                class="inputtext"
                :class="{ 'error': errors.password_confirmation }"  
              />
              <ErrorMessage 
                class="error-msg" 
                name="password_confirmation"
              />
            </div>
          </div>

          <div class="mb-2">
            <Field 
              name="termos" 
              type="checkbox" 
              class="pe-2 light mb-2" 
              :value="true"
              :class="{ 'error': errors.termos }"
            />
            <LabelFromYup 
              name="termos" 
              :schema="schema" 
              class="ps-2"
            />
            <router-link
              :to="{ name: 'Termos' }"
              class="btn icon p-0 btn-primary"
            >
              Termos e Condições de Uso
            </router-link>
            <ErrorMessage 
              class="error-msg" 
              name="termos"
            />
          </div>

          <div class="d-flex flex-column align-items-center">
            <button 
              class="btnAmarelo" 
              :disabled="isSubmitting"
            >
              Cadastrar-se
            </button>
            <font-awesome-icon
              v-show="isSubmitting" 
              icon="spinner" 
              class="spinner mt-3"
            />
          </div>
        </Form>
        <div>
          <div
            v-if="emFoco"
            class="bordaCinza w-100 p-3">
            <h2 class="align-items-center justify-content-center d-flex p-3">
              {{ emFoco?.nome }}
            </h2>
            <div class="trabalhos">
              <div 
                v-if="emFoco?.quantidade_opinioes"
                class="py-1"
              >
                <p>{{ emFoco?.quantidade_opinioes }} Opinião de mercado por mês</p>
              </div>
              <div 
                v-if="emFoco?.quantidade_avaliacoes"
                class="py-1"
              >
                <p>{{ emFoco?.quantidade_avaliacoes }} Análise de mercado por mês</p>
              </div>
              <div 
                v-if="emFoco?.quantidade_laudos"
                class="py-1"
              >
                <p>{{ emFoco?.quantidade_laudos }} PTAMS por mês</p>
              </div>
              <div 
                v-if="emFoco?.quantidade_pic"
                class="py-1"
              >
                <p>{{ emFoco?.quantidade_pic }} Pesquisa inteligente por mês</p>
              </div>
              <div 
                class="py-1"
                v-if="emFoco?.quantidade_busca_engenheiro">
                <p>{{ emFoco?.quantidade_busca_engenheiro }} Captação de Dados Imobiliários</p>
              </div>
              <div 
                class="py-1"
                v-if="emFoco?.quantidade_usuarios">
                <p>{{ emFoco?.quantidade_usuarios }} Captação de Dados Imobiliários </p>
              </div>
            </div>
            <!-- <div>
              <p>Acesso por x meses</p>
            </div> -->
            <div>
              <div 
                v-if="emFoco?.preco_sob_consulta"
                class="align-items-center justify-content-center d-flex p-3"
              >
                <h1>Preço sob consulta</h1>
              </div>
              <div 
                v-else
                class="align-items-center justify-content-center flex-column d-flex p-3"
              >
                <div class="d-flex gap-1 pb-1">
                  <p>12x</p>
                  <h2>R${{ calcularParcelas(emFoco?.valor) }}</h2>
                </div>
                <small>á vista R${{ dinheiro(emFoco?.valor) }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modal" class="modal-dialog modal-dialog-centered modal-overlay">
      <div class="modal-content">
        <div class="modal-header justify-content-between d-flex justify-content-between">
          <h3>Termos e Condições de Uso</h3>          
        </div>
        <div class="bordaCiano modal-body mt-3 px-2 py-3">
          <p class="text-start">
            Ao acessar e usar o AVALION, você irá concordar com os seguintes termos e condições de uso:
            As informações apresentadas pela Ferramenta são fornecidas exclusivamente com o propósito informativo e com o entendimento de que não há garantia de serem exatas, corretas, atuais ou completas por terem suas fontes obtidas publicamente na Internet/web e as conclusões tiradas a partir dessas informações e eventuais lesões a direito próprio ou de terceiros são de inteira responsabilidade do usuário/contratante.
            Embora todos os esforços sejam desenvolvidos para assegurar o rigor, exatidão e atualidade das informações dos dados mercadológicos, imóveis e seus registros relacionados, bem como garantir a qualidade e operacionalidade da ferramenta, os responsáveis por estas informações não assumem qualquer responsabilidade por erros, omissões ou falhas técnicas do sistema.
            É importante lembrar que imagens e impressões das informações do AVALION não substituem qualquer documento seja ele de natureza oficial ou apenas uma autorização, sendo que as informações disponibilizadas destinam-se à consulta, visualização e impressão e como informação de mercado com base em fontes externas de dados.
            Em caso de duvidas, sugestões ou problemas, estes, devem ser encaminhados através do formulário existente no site.
            Este sistema é protegido por leis de direitos autorais e tratados internacionais do Brasil com outros países em que possui relações diplomáticas, bem como por outras leis e tratados de propriedade intelectual.
            O software é licenciado para uso e não vendido.
            Dando continuidade a este processo, acreditamos que aceita os termos de condições e uso, como base para o relacionamento entre o Contratante e a Contratada.
          </p>
          <button class="btnRoxo mt-3" type="button" @click="closeModal">
            Concordo
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { cadastro as schema } from '@/consts/formSchema';
import dinheiro from '@/helpers/dinheiro';
import { useAlertStore } from '@/store/alert.store';
import { useAuthStore } from '@/store/auth.store';
import { useCepStore } from '@/store/buscaCep.store';
import { usePlanoStore } from '@/store/plano.store';
import { vMaska } from "maska/vue";
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const planoStore = usePlanoStore()
const { emFoco } = storeToRefs(planoStore);

const authStore = useAuthStore();

const alertStore = useAlertStore();
const route = useRoute();

const cepGet = useCepStore()
const { lista, chamadasPendentes  } = storeToRefs(cepGet);

const planoId = ref(null);
const modal = ref(false)

async function getCep(cep) {
  let ceptetste = cep.target.value.replace(/\D/g, '')
  try {
    await cepGet.getCep(ceptetste);
  } catch (error) {
    alertStore.error(error);
  }
}

const calcularParcelas = (valor) => {
  const valorNumerico = parseFloat(
    valor.replace('.', '').replace(',', '.')
  );
  
  if (isNaN(valorNumerico) || valorNumerico <= 0) {
    return "0,00";
  }

  const parcela = (valorNumerico / 12).toFixed(2);
  return parcela.replace('.', ',');
};


async function onSubmitCadastro (values) {
  try{
    const payload = {
      ...values,
      "plano_id": planoId.value
    };
    await authStore.cadastro(payload);  
  } catch (erros){
    alertStore.error(erros.message); 
  }
}

onMounted(async() => {
  planoId.value = route.query.plano_id;
  if(planoId.value){
    await planoStore.buscarItem(planoId.value)
  }

  const localStorageKey = `concordoTermos`;
  const modalDismissed = localStorage.getItem(localStorageKey);
  if(!modalDismissed){

    modal.value = true
  }
});

function closeModal() {
  const localStorageKey = `concordoTermos`;
  localStorage.setItem(localStorageKey, 'true');
  modal.value = false;
}

</script>

<style scoped>

.avalionFormLogin {
  max-width: inherit;
}

.avalionLogin{
  height: initial;
}

.avalionLogo {
  max-width: 340px;
  display: block;
  margin: auto;
}

.btn-primary{
  color: var(--roxo)
}

.btn-primary:hover, :not(.btn-check)+.btn:active {
  color: initial;
  background-color: initial;
}

.bordaCinza {
  width: 400px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  width: 625px;
  max-width: 100%;
  position: relative;
}
</style>