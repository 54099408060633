<template>
  <div>
    <div class="pb-4">
      <div class="d-flex justify-content-between align-items-center pb-2">
        <h3 class="pb-1">
          Pacotes Adicionais
        </h3>
      </div>
      <table class="w-100 mb-3">
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Opiniões de Mercado</th>
            <th>PTAMs</th>
            <th>Análises de Avaliação</th>
            <th>Usuários</th>
            <th>Adesão</th>
            <th>Expiração </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="item in adquiridos"
            :key="item.id"
          >
            <td>{{ item.quantidade_opinioes }}</td>
            <td>{{ item.quantidade_laudos }}</td>
            <td>{{ item.quantidade_analises }}</td>
            <td>{{ item.quantidade_usuarios }}</td>
            <td>{{ item.created_at ? dateToField(item.created_at) : '-' }}</td>
            <td>{{ item.data_expiracao ? dateToField(item.data_expiracao) : '-' }}</td>
          </tr>
          <tr v-if="chamadasPendentes.adquiridos">
            <td colspan="3">
              <font-awesome-icon
                icon="spinner" 
                class="spinner mt-3"
              />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="3">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!adquiridos.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>

      <div class="align-items-center justify-content-center d-flex p-3 mt-3">
        <router-link
          :to="{ 
            name: 'UpgradeAdicionais',
          }"
          class="btnAmarelo"
        >
          Adquirir Adicionais para o Meu Plano
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import dateToField from '@/helpers/dateToFiel';
import { useAuthStore } from '@/store/auth.store';
import { useClientesStore } from '@/store/clientes.store';
import { storeToRefs } from 'pinia';

const authStore = useAuthStore();
const { user } = storeToRefs(authStore)

const meusClientesStore = useClientesStore()
const { adquiridos, chamadasPendentes, erro } = storeToRefs(meusClientesStore);


async function iniciar(){
  if (user.value) {
    meusClientesStore.buscarPacotesAdiquiridos(user.value.id)
  }
}
iniciar()
</script>
