import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';
import { useAuthStore } from '@/store/auth.store';
// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useRequisicaoFinalidaeStore = defineStore('RequisicaoFinalidae', {
  state: () => ({
    requisicaoNavegacao: "",
    sucess: '',
    chamadasPendentes: {
      requisicaoNavegacao: false,
      sucess: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params) {
      this.chamadasPendentes.requisicaoNavegacao = true;
      this.erro = null;
      try {
      const authStore = useAuthStore();
      const token = authStore.token;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
        const response = await fetch(`${baseUrl}/get-counter?tipo_de_busca=${params}`, requestOptions)  
        const requisicaoNavegacao = await response.text();
        this.requisicaoNavegacao = requisicaoNavegacao;   
        return this.requisicaoNavegacao;
      } catch (erro) {
        this.erro = erro;
        console.error('Erro ao gerar pdf:', erro);
      } finally {
        this.chamadasPendentes.requisicaoNavegacao = false;
      }
    },

    async contador(params = {}){
      this.chamadasPendentes.sucess = true;
      this.erro = null;
      try {
        const response = await this.requestS.post(`${baseUrl}/post-counter`, params);
        this.sucess = response.status
        return this.sucess
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.sucess = false;
    },
  },
});
