<template>
  <div class="card-branco">
    <form
      @submit.prevent="onSubmitResposta"
    >
      <div 
        v-for="(pergunta, index) in listaPergunta" 
        :key="pergunta.id"
      >
        <label :for="`input-${index}`">{{ pergunta.name }}</label>
        <Field
          v-if="!pergunta.open_answer"
          :id="`input-${index}`"
          :name="`perguntas[${index}].answer`"
          as="select"
          class="inputtext mb-3"
        >
          <option value="">
            Selecionar
          </option>
          <option
            v-for="(opcao, idx) in JSON.parse(pergunta.answer)"
            :key="idx"
            :value="opcao"
          >
            {{ opcao }}
          </option>
        </Field>

        <Field
          v-else
          :name="`perguntas[${index}].answer`"
          type="text"
          class="inputtext mb-3"
          :id="`input-${index}`"
        />
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          SALVAR
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { useAlertStore } from '@/store/alert.store';
import { usePesquisaStore } from '@/store/pesquisas.store';
import { storeToRefs } from 'pinia';
import { Field, useForm } from 'vee-validate';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const PesquisaStore = usePesquisaStore()
const { listaPergunta } = storeToRefs(PesquisaStore);

const router = useRouter();
const route = useRoute();
const alertStore = useAlertStore();

const props = defineProps ({
  idPesquisa:{
    type: Number,
    default: 0
  }
})

const {
 isSubmitting, values, handleSubmit,
} = useForm()

const onSubmitResposta = handleSubmit(async (values) => {
  try {

    const respostas = {};

    listaPergunta.value.forEach((pergunta, index) => {
      const answer = values.perguntas[index]?.answer;
      respostas[pergunta.id] = answer;
    });

    const payload = {
      pesquisa_satisfacao_id: props.idPesquisa,
      respostas: respostas,
    };

    const resposta = await PesquisaStore.salvarResposta(payload); 

    if (resposta) {
      alertStore.success('Obrigado(a) pela colaboração');
      const localStorageKey = `nãoExibir_${props.idPesquisa}`;
      localStorage.setItem(localStorageKey, 'true');
      
      router.push({
        name: 'Dashboard',
       });
      PesquisaStore.$reset();
    }
  } catch (error) {
    alertStore.error(error);
  }
})

async function iniciar() {
  if(props.idPesquisa){
    await PesquisaStore.buscarItem(props.idPesquisa)
  }
}
iniciar()
</script>

<style scoped>

</style>