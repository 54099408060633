<template>
  <div class="menu_lateral menu">
    <CSidebar unfoldable>
      <CSidebarNav>
        <div>
          <div class="menu">
            <img
              class="mt-3"
              src="@/assets/imagens/avalionLogo.jpg"
              alt="Logo avalion"
            >
          </div>
        </div>
        <CNavItem href="/">
          <router-link to="/">
            <font-awesome-icon icon="house" />Dashboard
          </router-link>
        </CNavItem>

        <CNavItem href="/ptams">
          <router-link to="/ptams">
            <font-awesome-icon icon="briefcase" />PTAMs
          </router-link>
        </CNavItem>
        <CNavItem href="/analiseMercado">
          <router-link to="/analiseMercado">
            <font-awesome-icon icon="arrow-up-right-dots" />Análise de Mercado
          </router-link>
        </CNavItem>
        <CNavItem href="/opnioesMercado">
          <router-link to="/opnioesMercado">
            <font-awesome-icon icon="chart-bar" />Opiniões de Mercado
          </router-link>
        </CNavItem>
        <CNavItem href="/pesquisaInteligente">
          <router-link to="/pesquisaInteligente">
            <font-awesome-icon icon="lightbulb" />Pesquisa Inteligente
          </router-link>
        </CNavItem>
        <CNavItem 
          v-if="isSuperUser"
          href="/captacaoDadosImobiliarios"
        >
          <router-link to="/captacaoDadosImobiliarios">
            <font-awesome-icon icon="fa-solid fa-handshake-angle" />Dados imobiliário
          </router-link>
        </CNavItem>
        <CNavItem href="/imoveisVendidos">
          <router-link to="/imoveisVendidos">
            <font-awesome-icon icon="building" />Imóveis Vendidos
          </router-link>
        </CNavItem>
        <CNavItem href="/orcamentos">
          <router-link to="/orcamentos">
            <font-awesome-icon icon="hand-holding-dollar" />Orçamentos
          </router-link>
        </CNavItem>
        <CNavItem
          v-if="isSuperUser"
          href="/bairros"
        >
          <router-link to="/bairros">
            <font-awesome-icon icon="house-flag" />Bairros
          </router-link>
        </CNavItem>
        <CNavItem href="/amostras">
          <router-link to="/amostras">
            <font-awesome-icon icon="file-lines" />Amostras
          </router-link>
        </CNavItem>
        <CNavItem 
          v-if="isSuperUser"
          href="/clientes"
        >
          <router-link to="/clientes">
            <font-awesome-icon icon="fa-solid fa-users" />Clientes
          </router-link>
        </CNavItem>
        <CNavItem 
          v-if="isSuperUser"
          href="/pesquisas"
        >
          <router-link to="/pesquisas">
            <font-awesome-icon icon="fa-brands fa-searchengin" />pesquisas
          </router-link>
        </CNavItem>
        <CNavItem 
          v-if="isSuperUser" 
          href="/planos"
        >
          <router-link to="/planos">
            <font-awesome-icon icon="file-invoice-dollar" />Planos
          </router-link>
        </CNavItem>
      </CSidebarNav>
    </CSidebar>
  </div> 
</template>

<script setup>
import { useAuthStore } from '@/store/auth.store';
import { CNavItem, CSidebar, CSidebarNav } from '@coreui/vue';
import { storeToRefs } from 'pinia';

const authStore = useAuthStore();
const { isSuperUser } = storeToRefs(authStore)

</script>

<style>
.sidebar{
  background-color: var(--branco);
  max-width: 330px;
  border-radius: 34px;
  font-weight: 500;
}

.menu_lateral img{
  max-width: 248px;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
}

.menu_lateral nav{
  display: grid;
}

.sidebar-narrow-unfoldable {
  position: relative !important;
}

.sidebar-narrow, .sidebar-narrow-unfoldable:not(:hover) {
    --cui-sidebar-narrow-width: 5rem !important;
}

.sidebar{
  --cui-sidebar-width: 20rem !important;
}
</style>

