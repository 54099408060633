export default {
  "Regular": {
    nome: 'Regular',
    valor: 'Regular',
  },
  "Adicional": {
    nome: 'Adicional',
    valor: 'Adicional',
  },
  "Pacote": {
    nome: 'Pacote',
    valor: 'Pacote',
  },
  "Customizado": {
    nome: 'Customizado',
    valor: 'Customizado',
  },
};
