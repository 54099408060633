<template>  
  <div class="tabela">
    <table class="w-100">
      <font-awesome-icon
        v-show="isSubmitting" 
        icon="spinner" 
        class="spinner mt-3"
      />
      <col>
      <col>
      <col>
      <col>
      <col>
      <thead>
        <tr>
          <th>Plano</th>
          <th>Descrição</th>
          <th>Ordem</th>
          <th>Opiniões</th>
          <th>Pesquisa inteligente</th>
          <th>Status</th>
          <th>Valor</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in planosIndividuais"
          :key="item.id"
        >
          <td class="descricao">{{ item.nome }}</td>
          <td class="descricao">{{ item.descricao }}</td>
          <td>{{ item.ordem }}</td>
          <td>{{ item.quantidade_opinioes }}</td>
          <td>{{ item.quantidade_pic }}</td>
          <td>{{ item.ativo ? "Ativo" : "Inativo" }}</td>
          <td>R${{ item.valor }}</td>
          <td class="tdBtnAcao">
            <router-link
              :to="{ name: 'PlanoEditar', params: { planoId: item.id } }"
              class="icon"
            >
              <font-awesome-icon
                icon="pen-to-square"
              />
            </router-link>
          </td>
        </tr>
        <tr v-if="chamadasPendentes.planosIndividuais">
          <td colspan="3">
            <font-awesome-icon
              icon="spinner" 
              class="spinner mt-3"
            />
            Carregando
          </td>
        </tr>
        <tr v-else-if="erro">
          <td colspan="3">
            Erro: {{ erro }}
          </td>
        </tr>
        <tr v-else-if="!planosIndividuais.length">
          <td colspan="3">
            Nenhum resultado encontrado.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { usePlanoStore } from '@/store/plano.store';
import { storeToRefs } from 'pinia';

const planoStore = usePlanoStore()
const { planosIndividuais, chamadasPendentes, erro } = storeToRefs(planoStore);
</script>

<style scoped>
.descricao{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
}
</style>