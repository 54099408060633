<template>
  <div class="card-branco">
    <router-link
      to="/usuarios"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <div>
      <h1 class="mb-3">
        Selecione um novo plano de assinatura
      </h1>
      <section class="d-grid gap-3">
        <div 
          v-for="item in pacotes.filter( plano => plano.valor !== '0,00' )"
          :key="item.id"
        >
          <div
            class="bordaCinza w-100 p-3">
            <h2 class="align-items-center justify-content-center d-flex p-3">
              {{ item.nome }}
            </h2>
            <div class="trabalhos">
              <div 
                v-if="item.quantidade_opinioes"
                class="py-1"
              >
                <p>{{ item.quantidade_opinioes }} Opinião de mercado por mês</p>
              </div>
              <div 
                v-if="item.quantidade_avaliacoes"
                class="py-1"
              >
                <p>{{ item.quantidade_avaliacoes }} Análise de mercado por mês</p>
              </div>
              <div 
                v-if="item.quantidade_laudos"
                class="py-1"
              >
                <p>{{ item.quantidade_laudos }} PTAMS por mês</p>
              </div>
              <div 
                v-if="item.quantidade_pic"
                class="py-1"
              >
                <p>{{ item.quantidade_pic }} Pesquisa inteligente por mês</p>
              </div>
              <div 
                class="py-1"
                v-if="item.quantidade_busca_engenheiro">
                <p>{{ item.quantidade_busca_engenheiro }} Captação de Dados Imobiliários</p>
              </div>
              <div 
                class="py-1"
                v-if="item.quantidade_usuarios">
                <p>{{ item.quantidade_usuarios }} Captação de Dados Imobiliários </p>
              </div>
            </div>
            <!-- <div>
              <p>Acesso por x meses</p>
            </div> -->
            <div>
              <div 
                v-if="item.preco_sob_consulta"
                class="align-items-center justify-content-center d-flex p-3"
              >
                <h1>Preço sob consulta</h1>
              </div>
              <div 
                v-else
                class="align-items-center justify-content-center flex-column d-flex p-3"
              >
                <div class="d-flex gap-1 pb-1">
                  <p>12x</p>
                  <h2>R${{ calcularParcelas(item.valor) }}</h2>
                </div>
                <small>á vista R${{ dinheiro(item.valor) }}</small>
              </div>
            </div>
            <div class="align-items-center justify-content-center d-flex p-3">
              <router-link
                :to="{ 
                  name: 'pagamento',
                  params: { planoId: item.id} 
                }"
                class="btnAmarelo"
              >
                Contratar plano 
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div v-if="pacotesAdicionais">
      <h1 class="my-3">
        Selecione um pacote adicional
      </h1>
      <section class="d-grid gap-3">
        <div 
          v-for="item in pacotesAdicionais.filter( plano => plano.valor !== '0,00' )"
          :key="item.id"
        >
          <div
            class="bordaCinza w-100 p-3">
            <h2 class="align-items-center justify-content-center d-flex p-3">
              {{ item.nome }}
            </h2>
            <div class="trabalhos">
              <div 
                v-if="item.quantidade_opinioes"
                class="py-1"
              >
                <p>{{ item.quantidade_opinioes }} Opinião de mercado por mês</p>
              </div>
              <div 
                v-if="item.quantidade_avaliacoes"
                class="py-1"
              >
                <p>{{ item.quantidade_avaliacoes }} Análise de mercado por mês</p>
              </div>
              <div 
                v-if="item.quantidade_laudos"
                class="py-1"
              >
                <p>{{ item.quantidade_laudos }} PTAMS por mês</p>
              </div>
              <div 
                v-if="item.quantidade_pic"
                class="py-1"
              >
                <p>{{ item.quantidade_pic }} Pesquisa inteligente por mês</p>
              </div>
              <div 
                class="py-1"
                v-if="item.quantidade_busca_engenheiro">
                <p>{{ item.quantidade_busca_engenheiro }} Captação de Dados Imobiliários</p>
              </div>
              <div 
                class="py-1"
                v-if="item.quantidade_usuarios">
                <p>{{ item.quantidade_usuarios }} Captação de Dados Imobiliários </p>
              </div>
            </div>
            <!-- <div>
              <p>Acesso por x meses</p>
            </div> -->
            <div>
              <div 
                v-if="item.preco_sob_consulta"
                class="align-items-center justify-content-center d-flex p-3"
              >
                <h1>Preço sob consulta</h1>
              </div>
              <div 
                v-else
                class="align-items-center justify-content-center flex-column d-flex p-3"
              >
                <div class="d-flex gap-1 pb-1">
                  <p>12x</p>
                  <h2>R${{ calcularParcelas(item.valor) }}</h2>
                </div>
                <small>á vista R${{ dinheiro(item.valor) }}</small>
              </div>
            </div>
            <div class="align-items-center justify-content-center d-flex p-3">
              <router-link
                :to="{ 
                  name: 'pagamento',
                  params: { planoId: item.id} 
                }"
                class="btnAmarelo"
              >
                Contratar plano 
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import dinheiro from '@/helpers/dinheiro';
import { usePlanoStore } from '@/store/plano.store';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

const planoStore = usePlanoStore()
const { pacotes, pacotesAdicionais } = storeToRefs(planoStore);

const calcularParcelas = (valor) => {
  const valorNumerico = parseFloat(
    valor.replace('.', '').replace(',', '.')
  );
  
  if (isNaN(valorNumerico) || valorNumerico <= 0) {
    return "0,00";
  }

  const parcela = (valorNumerico / 12).toFixed(2);
  return parcela.replace('.', ',');
};


async function iniciar(){
 await planoStore.buscarPacotes()
 await planoStore.buscarPacotesAdicionais()
}
iniciar()
</script>

<style scoped>
.bordaCinza{
  min-height: 500px;

}
.trabalhos p{
  border-bottom: 1px solid var(--cinza);
  padding-bottom: 8px;
}

.d-grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
</style>