import { defineStore } from 'pinia';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useBairroStore = defineStore('bairro', {
  state: () => ({
    lista: [],
    emFoco: null,
    listaCidade: [],
    chamadasPendentes: {
      lista: false,
      emFoco: false,
      listaCidade: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/neighborhoods?state=${params.estado}&city=${params.cidade}`);
        this.lista =  request.neighborhoods
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },

    async buscarItem(id = 0, params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/neighborhood/${id}`, params);
        this.emFoco = resposta.neighborhood
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },
    
    async buscarCidade(estado) {
      this.chamadasPendentes.listaCidade = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/neighborhoods?state=${estado}`);
        this.listaCidade = resposta.cities
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.listaCidade = false;
    },

    async excluirItem(id) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        await this.requestS.delete(`${baseUrl}/neighborhood/${id}`);
        this.chamadasPendentes.lista = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.lista = false;
        return false;
      }
    },

    async salvarItem(params = {}, id = null) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        if (id) {
          await this.requestS.put(`${baseUrl}/neighborhood/${id}`, params);
        } else {
          await this.requestS.post(`${baseUrl}/neighborhood`, params);
        }

        this.chamadasPendentes.emFoco = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },
  },
});
