<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>Planos</h1>
      <router-link
        :to="{ path: '/planos/novoPlano' }"
        class="btnRoxo"
      >
        Novo plano
      </router-link>
    </div>
    <strong
      style="color: var(--roxo)"
    >
      Filtrar por:
    </strong>
    <form
      class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
    >
      <div class="d-flex gap-3 align-items-center">
        <div class="d-flex gap-2 mt-4 align-items-center">
          <input
            v-model="ativo"
            name="ativo"
            type="checkbox"
            class="inputcheckbox"
            @change="atualizarUrl"
          >
          <label class="pb-0">Planos ativos</label>
        </div>
      </div>
    </form>
    <CTabs active-item-key="adicionais">
      <CTabList variant="tabs">
        <CTab item-key="regular">
          Planos regulares
        </CTab>
        <CTab item-key="adicionais">
          Planos Adicionais
        </CTab>
        <CTab item-key="customizados">
          Planos Diamante (Customizados)
        </CTab>
        <CTab item-key="individual">
          Individuais
        </CTab>
      </CTabList>
      <CTabContent class="">
        <CTabPanel 
          class="pt-3"
          item-key="regular"
        >
          <ListaRegular/>
        </CTabPanel>
        <CTabPanel 
          class="pt-3" 
          item-key="adicionais"
        >
          <ListaAdicional/>
        </CTabPanel>
        <CTabPanel 
          class="pt-3" 
          item-key="customizados"
        >
          <ListaCustomizado/>
        </CTabPanel>
        <CTabPanel 
          class="pt-3" 
          item-key="individual"
        >
          <ListaIndividual/>
        </CTabPanel>
      </CTabContent>
    </CTabs>
  </div>
</template>

<script setup>
import ListaAdicional from './ListaAdicional.vue';
import ListaCustomizado from './ListaCustomizado.vue';
import ListaIndividual from './ListaIndividual.vue';
import ListaRegular from './ListaRegular.vue';

import { usePlanoStore } from '@/store/plano.store';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const planoStore = usePlanoStore()

const ativo = ref(route.query.ativo === 'true' || route.query.ativo === undefined ? true : false);
const consulta = { ativo: ativo.value };

function atualizarUrl() {
  consulta.ativo = ativo.value;
  router.push({
    query: consulta,
  });
}

watch(
  () => route.query.ativo,
  (newVal) => {
    ativo.value = newVal === 'true' || newVal === undefined ? true : false;
    planoStore.$reset();
    planoStore.buscarTudo({ ativo: ativo.value });
  },
  { immediate: true }
);

</script>

<style scoped>
.descricao{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
}
</style>