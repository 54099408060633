<template>
  <div>
    <h2 class="mb-3">
      Criar/Editar Perguntas
    </h2>
    <form
      @submit.prevent="onSubmitPergunta"
    >
      <div class="d-flex gap-3 align-items-center">
        <div class="w-100 mb-3">
          <LabelFromYup 
            name="name" 
            :schema="schema"
          />
          <Field
            name="name"
            placeholder="Título"
            type="text"
            class="inputtext"
          />
          <ErrorMessage
            class="error-msg"
            name="name"
          />
        </div>

        <div class="w-25">
          <Field
            v-model="open_answer"
            name="open_answer"
            type="checkbox"
            :value="true"
            class="inputcheckbox"
          />
          <LabelFromYup
            name="open_answer"
            :schema="schema"
            class="ps-2 pb-0"
          />
          <ErrorMessage 
            class="error-msg"
            name="open_answer"
          />
        </div>
      </div>

      <div class="mb-3 w-100">
        <LabelFromYup
          :schema="schema"
          name="order"
          class="label"
        />
        <Field
          name="order"
          type="number"
          min="0"
          class="inputtext"
        />
        <ErrorMessage
          name="order"
          class="error-msg"
        />
      </div>

      <div 
        v-if="!open_answer" 
        class="mb-3"
      >
        <FieldArray 
          v-slot="{ fields, push, remove }" 
          name="answer"
        >
          <div 
            v-for="(field, index) in fields" 
            :key="field.key" 
            class="d-flex align-items-center mb-3"
          >
            <div class="w-100">
              <Field
                :name="`answer[${index}]`"
                placeholder="Resposta"
                type="text"
                class="inputtext"
              />
              <ErrorMessage
                :name="`answer[${index}]`"
                class="error-msg"
              />
            </div>
            <button 
              type="button" 
              class="btn btn-danger ms-2"
              @click="remove(index)" 
            >
              Remover
            </button>
          </div>
          <button 
            type="button" 
            class="btn w-100 btn-undefined d-flex align-items-center addlink"
            @click="push('')" 
          >
            <font-awesome-icon 
              class="pe-2" 
              icon="circle-plus"
            />
            Adicionar Resposta
          </button>
        </FieldArray>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          SALVAR
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { pergunta as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { usePesquisaStore } from '@/store/pesquisas.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, FieldArray, useForm } from 'vee-validate';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const PesquisaStore = usePesquisaStore()
const { emFoco, pesquisaId: id } = storeToRefs(PesquisaStore);

const router = useRouter();
const route = useRoute();
const alertStore = useAlertStore();

const open_answer = ref(true)

const props = defineProps({
  pesquisaId: {
    type: Number,
    default: 0
  }
})

const {
  errors, isSubmitting, handleSubmit, values, resetForm
} = useForm({
  validationSchema: schema,
});

const onSubmitPergunta = handleSubmit(async (values) => {
  try {
    const payload = {
      ...values,
      "open_answer": open_answer.value,
      "pesquisa_satisfacao_id": props.pesquisaId,
    };

    const resposta = await PesquisaStore.salvarPergunta(payload)

    if (resposta) {
      alertStore.success('Item adicionado com sucesso!');
      await PesquisaStore.buscarItem(props.pesquisaId)
      resetForm()
    }
  } catch (error) {
    alertStore.error(error);
  }
})

</script>

<style scoped>
.addlink svg {
  width: auto;
}

.addlink{
  color: var(--roxo);
  background-color: transparent;
  border: none;
}
</style>