export default {
  "0": {
    nome: '0%',
    valor: '0',
  },
  "8": {
    nome: '8%',
    valor: '8',
  },
  "10": {
    nome: '10%',
    valor: '10',
  },
  "12": {
    nome: '12%',
    valor: '12',
  },
};
