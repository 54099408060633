<template>
  <div class="mt-4">
    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Pergunta</th>
            <th>Resposta</th>
            <th>Status</th>
            <th>Ordem</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <template 
            v-for="item in listaPergunta" 
            :key="item.id"
          >
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ formatarAcentos(item.answer) }}</td>
              <td>{{ item.open_answer ? "Sim" : "Não"}}</td>
              <td>{{ item.order }}</td>
              <td class="tdBtnAcao">
                <button
                  class="icon"
                  arial-label="editar"
                  @click="editarPergunta(item)"
                >
                  <font-awesome-icon icon="pen-to-square" />
                </button>
              </td>
            </tr>
          </template>         
        </tbody>
      </table>
    </div>

    <div
      v-if="mostrarModalPergunta"
      class="modal fade show"
      style="display: block; background-color: rgba(0,0,0,0.5);" 
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header justify-content-between">
            <h3>Editar pergunta</h3>
            <button 
              type="button" 
              class="Modalclose"
              aria-label="Close"
              @click="fecharModalPergunta"
            >
              <font-awesome-icon icon="circle-xmark" />
            </button>
          </div>
          <div class="modal-body">
            <form
              @submit.prevent="onSubmitPergunta"
            >
              <div class="w-100 mb-3">
                <LabelFromYup 
                  name="name" 
                  :schema="schema"
                />
                <Field
                  name="name"
                  placeholder="Título"
                  type="text"
                  class="inputtext"
                />
                <ErrorMessage
                  class="error-msg"
                  name="name"
                />
              </div>

              <div class="mb-3 w-100">
                <LabelFromYup
                  :schema="schema"
                  name="order"
                  class="label"
                />
                <Field
                  name="order"
                  type="number"
                  min="0"
                  class="inputtext"
                />
                <ErrorMessage
                  name="order"
                  class="error-msg"
                />
              </div>

              <div class="w-25 mb-3">
                <Field
                  name="open_answer"
                  type="checkbox"
                  :value="true"
                  class="inputcheckbox"
                />
                <LabelFromYup
                  name="open_answer"
                  :schema="schema"
                  class="ps-2 pb-0"
                />
                <ErrorMessage 
                  class="error-msg"
                  name="open_answer"
                />
              </div>

              <div 
                v-if="!formValues.open_answer"
                class="mb-3"
              >
                <FieldArray 
                  v-slot="{ fields, push, remove }" 
                  name="answer"
                >
                  <div 
                    v-for="(field, index) in fields" 
                    :key="field.key" 
                    class="d-flex align-items-center mb-3"
                  >
                    <div class="w-100">
                      <Field
                        :name="`answer[${index}]`"
                        placeholder="Resposta"
                        type="text"
                        class="inputtext"
                      />
                      <ErrorMessage
                        :name="`answer[${index}]`"
                        class="error-msg"
                      />
                    </div>
                    <button 
                      type="button" 
                      class="btn btn-danger ms-2"
                      @click="remove(index)" 
                    >
                      Remover
                    </button>
                  </div>
                  <button 
                    type="button" 
                    class="btn w-100 btn-undefined d-flex align-items-center addlink"
                    @click="push('')" 
                  >
                    <font-awesome-icon 
                      class="pe-2" 
                      icon="circle-plus"
                    />
                    Adicionar Resposta
                  </button>
                </FieldArray>
              </div>

              <div class="d-flex flex-column align-items-center mt-4">
                <button 
                  class="btnRoxo" 
                  :disabled="isSubmitting"
                >
                  SALVAR
                </button>
                <font-awesome-icon
                  v-show="isSubmitting" 
                  icon="spinner" 
                  class="spinner mt-3"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { pergunta as schema } from '@/consts/formSchema';
import formatarAcentos from '@/helpers/formatarAcentos';
import { useAlertStore } from '@/store/alert.store';
import { usePesquisaStore } from '@/store/pesquisas.store';
import { ErrorMessage, Field, FieldArray, useForm } from 'vee-validate';
import { ref } from 'vue';

const alertStore = useAlertStore();
const PesquisaStore = usePesquisaStore()

const itemSelecionado = ref(null)
const mostrarModalPergunta = ref(false)
const props = defineProps({
  pesquisaId: {
    type: Number,
    default: 0
  },
  listaPergunta:{
    type: Array,
    default: () => [],
  }
})

function editarPergunta(item) {
  itemSelecionado.value = item;
  mostrarModalPergunta.value = true;
  resetForm({
    values: {
      ...itemSelecionado.value,
      answer: itemSelecionado.value.answer ? JSON.parse(itemSelecionado.value.answer) : [],
      open_answer: itemSelecionado.value.open_answer,
    },
  });
}

const {
  errors, isSubmitting, handleSubmit, values, resetForm
} = useForm({
  validationSchema: schema,
});

const formValues = values;

const onSubmitPergunta = handleSubmit(async (values) => {
  try {
    const payload = {
      ...values,
      "pesquisa_satisfacao_id": props.pesquisaId,
    };

    const resposta = await PesquisaStore.salvarPergunta(payload, itemSelecionado.value?.id)

    if (resposta) {
      alertStore.success('Item adicionado com sucesso!');
      await PesquisaStore.buscarItem(props.pesquisaId)
      resetForm()
      mostrarModalPergunta.value = false;
    }
  } catch (error) {
    alertStore.error(error);
  }
})

function fecharModalPergunta(){
  mostrarModalPergunta.value = false;
}

async function iniciar(){
  await PesquisaStore.buscarTudo()
}
iniciar()

</script>

<style scoped>
.addlink svg {
  width: auto;
}

.addlink{
  color: var(--roxo);
  background-color: transparent;
  border: none;
}

.analise{
  width: 250px
}
</style>