<template>
  <div>
    <div class="pb-4">
      <div class="d-flex justify-content-between align-items-center pb-2">
        <h1 class="pb-2">Histórico de Logins</h1>
      </div>
      <table class="w-100 mb-3">
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Data</th>
            <th>Última Atividade</th>
            <th>Endereço IP</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="item in historicoNavegacao"
            :key="item.id"
          >
            <td>{{ dateToField(item.data) }}</td>
            <td>{{ dateToField(item.last_activity) }}</td>
            <td>{{ item.endereco_ip }}</td>
          </tr>
          <tr v-if="chamadasPendentes.historicoNavegacao">
            <td colspan="3">
              <font-awesome-icon
                icon="spinner" 
                class="spinner mt-3"
              />
              Carregando
            </td>
          </tr>
          <tr v-else-if="!historicoNavegacao.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
      <span 
        v-if="historicoNavegacao"
        class="p-2"
      >
        Total: <strong>{{ historicoNavegacao?.length }}</strong>
      </span>
    </div>
  </div>
</template>

<script setup>
import dateToField from '@/helpers/dateToFiel';
import { useHistoricoAcessosStore } from '@/store/historicoAcessos.store';
import { storeToRefs } from 'pinia';

const historicoAcessosStore = useHistoricoAcessosStore()
const { historicoNavegacao, chamadasPendentes } = storeToRefs(historicoAcessosStore);

</script>

<style lang="scss" scoped>

</style>