<template>
  <div>
    <div>
      <strong
        style="color: var(--roxo)"
      >
        Filtrar por:
      </strong>
      <form
        class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
        @submit.prevent="atualizarUrl"
      >
        <div class="d-flex gap-3 align-items-center">
          <div>
            <label>Período</label>
            <input 
              id="period"
              v-model.trim="period"
              class="inputtext"
              name="period"
              type="month"
            >
          </div>
        </div>
        <div class="d-flex gap-3">
          <button @click="limparFiltro" type="button" class="btnLabel">
            <font-awesome-icon
              icon="trash"
            />
            Limpar
          </button>
          <button class="btnAmarelo">
            Pesquisar
          </button>
        </div>
      </form>
    </div>
    <div class="bordaCinza">
      <div class="d-flex p-3 justify-content-around">
        <div class="text-center analise">
          <label>PTAMs Feitos</label>
          <p>{{ emFoco?.countLaudos }}</p>
        </div>
        <div class="text-center analise">
          <label>Análises Feitas</label>
          <p>{{ emFoco?.countAnalises }}</p>
        </div>
        <div class="text-center analise">
          <label>Opiniões de Mercado Feitas</label>
          <p>{{ emFoco?.countOpinioes }}</p>
        </div>
        <div class="text-center analise">
          <label>Pesquisas Inteligente Feitas</label>
          <p>{{ emFoco?.countPics }}</p>
        </div>
      </div>
      <div class="d-flex p-3 justify-content-around">
        <div class="text-center analise">
          <label>PTAMs Feitos {{ emFoco?.periodo }}</label>
          <p>{{ emFoco?.countLaudosOnMonth }}</p>
        </div>
        <div class="text-center analise">
          <label>Análises Feitas {{ emFoco?.periodo }}</label>
          <p>{{ emFoco?.countAnalisesOnMonth }}</p>
        </div>
        <div class="text-center analise">
          <label>Opiniões de Mercado Feitas {{ emFoco?.periodo }}</label>
          <p>{{ emFoco?.countOpinioesOnMonth }}</p>
        </div>
        <div class="text-center analise">
          <label>Pesquisas Inteligente Feitas {{ emFoco?.periodo }}</label>
          <p>{{ emFoco?.countPicsOnMonth }}</p>
        </div>
      </div>
      <div class="d-flex p-3 justify-content-around">
        <div class="text-center analise">
          <label>Buscas de PTAMs realizadas {{ lista?.periodo }}</label>
          <p>{{ emFoco?.getCountRequestsPtam }}</p>
        </div>
        <div class="text-center analise">
          <label>Buscas de Análises realizadas {{ lista?.periodo }}</label>
          <p>{{ emFoco?.getCountRequestsAnalise }}</p>
        </div>
        <div class="text-center analise">
          <label>Buscas de Opiniões de Mercado realizadas {{ lista?.periodo }}</label>
          <p>{{ emFoco?.getCountRequestsOpiniao }}</p>
        </div>
        <div class="text-center analise">
          <label>Buscas de Pesquisas Inteligente realizadas {{ lista?.periodo }}</label>
          <p>{{ emFoco?.getCountRequestsPic }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useClientesStore } from '@/store/clientes.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const meusClientesStore = useClientesStore()
const { emFoco } = storeToRefs(meusClientesStore);

const props = defineProps({
  clienteId: {
    type: Number,
    default: 0,
  },
});

const period = ref(route.query.period)

function atualizarUrl() {
  router.push({
    query: {
      ...route.query,
      period: period.value
    },
  });
}

watch([
  () => route.query.period,
], () => {
  meusClientesStore.$reset();
  meusClientesStore.buscarItem( props.clienteId, {
    period: route.query.period || ''
  });
}, { immediate: true });

function limparFiltro(){
  router.push({
    query: ''
  });
   meusClientesStore.buscarItem(props.clienteId)
}
</script>

<style scoped>

</style>