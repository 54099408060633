<template>
  <div>
    <div class="pb-4">
      <div class="d-flex justify-content-between align-items-center pb-2">
        <h1 class="pb-2">Pagamentos</h1>
      </div>
      <table class="w-100 mb-3">
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Método de Pagamento</th>
            <th>Data de Criação</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="item in pagamentos"
            :key="item.id"
          >
            <td>{{ item.metodo }}</td>
            <td>{{ dateToField(item.created_at) }}</td>
            <td>{{ dinheiro(item.PAYMENTINFO_0_AMT) }}</td>
          </tr>
          <tr v-if="chamadasPendentes">
            <td colspan="3">
              <font-awesome-icon
                icon="spinner" 
                class="spinner mt-3"
              />
              Carregando
            </td>
          </tr>
          <tr v-else-if="!pagamentos.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import dateToField from '@/helpers/dateToFiel';
import dinheiro from '@/helpers/dinheiro';
import { useClientesStore } from '@/store/clientes.store';
import { storeToRefs } from 'pinia';

const meusClientesStore = useClientesStore()

const { pagamentos } = storeToRefs(meusClientesStore);
const props = defineProps({
  clienteId: {
    type: Number,
    default: 0,
  },
});

async function iniciar(){
  if ( props.clienteId){
    await meusClientesStore.buscarPagamentos(props.clienteId)
  }
}
iniciar()
</script>

<style lang="scss" scoped>

</style>