export default {
  "true": {
    nome: 'Sim',
    valor: true,
  },
  "false": {
    nome: 'Não',
    valor: false,
  },
};
