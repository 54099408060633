import { defineStore } from 'pinia';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const usePlanoStore = defineStore('plano', {
  state: () => ({
    planosAdicionais: [],
    planosCustomizados: [],
    planosIndividuais: [],
    planosRegulares: [],
    pacotesAdicionais: [],
    pacotes: [],
    emFoco: null,
    chamadasPendentes: {
      planosAdicionais: false,
      planosCustomizados: false,
      planosIndividuais: false,
      planosRegulares: false,
      pacotesAdicionais: false,
      pacotes: false,
      emFoco: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(ativo = {}) {
      this.chamadasPendentes.planosAdicionais = true;
      this.chamadasPendentes.planosCustomizados = true;
      this.chamadasPendentes.planosIndividuais = true;
      this.chamadasPendentes.planosRegulares = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/planos`, ativo);
        this.planosAdicionais =  request.planosAdicionais
        this.planosCustomizados =  request.planosCustomizados
        this.planosIndividuais =  request.planosIndividuais
        this.planosRegulares =  request.planosRegulares

      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.planosAdicionais = false;
      this.chamadasPendentes.planosCustomizados = false;
      this.chamadasPendentes.planosIndividuais = false;
      this.chamadasPendentes.planosRegulares = false;
    },

    async buscarItem(id = 0, params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/plano/${id}`, params);
        this.emFoco = resposta.plano
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },
    
    async buscarPacotes() {
      this.chamadasPendentes.pacotes = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/planos/regulares`);
        this.pacotes = resposta.planosRegulares
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.pacotes = false;
    },

    async buscarPacotesAdicionais() {
      this.chamadasPendentes.pacotesAdicionais = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/planos/pacotes`);
        this.pacotesAdicionais = resposta.adicionais
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.pacotesAdicionais = false;
    },

    async salvarItem(params = {}, id = null) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        if (id) {
          await this.requestS.put(`${baseUrl}/plano/${id}`, params);
        } else {
          await this.requestS.post(`${baseUrl}/plano`, params);
        }

        this.chamadasPendentes.emFoco = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },
  },
});
