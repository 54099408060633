<template>
  <div class="d-grid justify-content-center">
    <div class="credit-card">
      <div class="d-flex justify-content-between align-items-center card-logo">
        <img src="../assets/imagens/chip.png">
        <h2>Card</h2>
      </div>
      <div class="card-number">
        <h2>{{ card_number}}</h2>
      </div>
      <div class="card-details">
        <div class="card-holder">
          <span>Nome</span>
          <p>{{ holder_name }}</p>
        </div>
        <div class="card-expiration">
          <span>Validade</span>
          <p>{{ card_expiration }}</p>
        </div>
      </div>
    </div>
    <div class="card-branco">
      <Form
        v-slot="{ errors, isSubmitting }"
        :validation-schema="schema"
        @submit="enviarPagamento"
      >
        <div class="mb-3">
          <LabelFromYup 
            name="holder_name"
            :schema="schema"
            :required="true"
          />
          <Field 
            v-model="holder_name"
            name="holder_name" 
            placeholder="Nome do titular" 
            type="text" 
            maxlength="40"
            class="inputtext"
            :class="{ 'error': errors.holder_name }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="holder_name"
          />
        </div>

        <div class="mb-3">
          <LabelFromYup 
            name="card_number"
            :schema="schema"
            :required="true"
          />
          <Field 
            v-model="card_number"
            v-maska
            data-maska="#### #### #### ####"
            name="card_number" 
            placeholder="Número do cartão" 
            type="text"
            maxlength="19"
            class="inputtext"
            :class="{ 'error': errors.card_number }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="card_number"
          />
        </div>

        <div class="d-flex gap-3">
          <div class="mb-3">
            <LabelFromYup 
              name="card_expiration"
              :schema="schema"
              :required="true"
            />
            <Field
              v-model="card_expiration"
              v-maska
              data-maska="##/##"
              name="card_expiration" 
              placeholder="Data de expiração" 
              type="text"
              maxlength="19"
              class="inputtext"
              :class="{ 'error': errors.card_expiration }"
            />
            <ErrorMessage 
              class="error-msg" 
              name="card_expiration"
            />
          </div>
  
          <div class="mb-3">
            <LabelFromYup 
              name="card_cvv"
              :schema="schema"
              :required="true"
            />
            <Field 
              name="card_cvv" 
              placeholder="Código CVC" 
              type="text"
              maxlength="3"
              class="inputtext"
              :class="{ 'error': errors.card_cvv }"
            />
            <ErrorMessage 
              class="error-msg" 
              name="card_cvv"
            />
          </div>
        </div>
        

        <div class="d-flex flex-column align-items-center">
          <button 
            class="btnAmarelo" 
            :disabled="isSubmitting"
          >
            Pagar
          </button>
          <font-awesome-icon 
            v-show="isSubmitting" 
            icon="spinner" 
            class="spinner mt-3"
          />
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
import { pagamento as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { usePagamentoStore } from '@/store/pagamento.store';
import { vMaska } from "maska/vue";
import { ErrorMessage, Field, Form } from 'vee-validate';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const alertStore = useAlertStore();
const pagamento = usePagamentoStore();

const card_number = ref('')
const holder_name = ref('')
const card_expiration = ref('')

const props = defineProps({
  planoId: {
    type: Number,
    default: 0
  }
})

async function enviarPagamento(values) {
  try {
    const payload ={
      ... values,
      "plan_id": props.planoId,
    }
    const resposta = await pagamento.enviarPagamento(payload)
    if(resposta){
      router.push({
        name: "Dashboard",
      });
    } 
  } catch (erro) {
    alertStore.error(erro);
  }
}
</script>

<style scoped>
p, h2, .card-details span{
  color: var(--branco);
}
.credit-card {
  background: linear-gradient(135deg, #3a2256 0%, #653C96 50%, #9d4edd 100%);
  box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
  width: 350px;
  height: 200px;
  border-radius: 15px;
  padding: 25px 15px;
  position: relative;
  font-family: 'Arial', sans-serif;
  margin-bottom: -130px;
}

.card-logo {
  margin-bottom: 15px;
  padding: 0 10px;
}

.card-logo h2{
  font-size: 30px;
}

.card-logo img{
  max-width: 50px;
}

.card-number h2{
  font-weight: 500;
  text-align: center;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.card-details {
  display: flex;
  justify-content: space-between;
}

.card-holder, .card-expiration {
  font-size: 14px;
}

.card-holder span, .card-expiration span {
  display: block;
  font-size: 12px;
  opacity: 0.8;
}

.card-branco{
  padding-top: 90px;
}

.d-grid{
  justify-items: center;
}
</style>