<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>Opiniões de Mercado</h1>
      <router-link
        :to="{ name: 'OpiniaoMercadoCriar' }"
        class="btnRoxo"
      >
        Nova opiniões de Mercado
      </router-link>
    </div>
    <div class="py-3 px-3 bordaAmarela mb-3">
      <p>
        Tem dúvidas de como fazer uma Opinião de Mercado? Acesse o tutorial 
        <a
          target="_blank"
          href="https://www.youtube.com/watch?v=UDH1OQV5JbQ">
          aqui
        </a>.
      </p>
    </div>
    <strong
      style="color: var(--roxo)"
    >
      Filtrar por:
    </strong>
    <form
      class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
      @submit.prevent="atualizarUrl"
    >
      <div class="d-flex gap-3 align-items-center">
        <div class="inputtextMenor">
          <label>Id</label>
          <input
            id="id"
            v-model.trim="idBusca"
            placeholder="ID"
            class="inputtext "
            name="id"
            type="text"
          >
        </div>

        <div class="mw-100">
          <label>Endereço</label>
          <input
            id="endereco"
            v-model.trim="endereco"
            placeholder="Endereço"
            class="inputtext"
            name="endereco"
            type="text"
          >
        </div>

        <div>
          <label>Bairro</label>
          <input
            id="bairro"
            v-model.trim="bairro"
            placeholder="Bairro"
            class="inputtext"
            name="bairro"
            type="text"
          >
        </div>

        <div>
          <label>Tipo de negócio</label>
          <select
            id="tipo_negocio"
            v-model.trim="tipo_negocio"
            class="inputtext"
            name="tipo_negocio"
          >
            <option 
              value=""
            />
            <option
              v-for="item in Object.values(tipoNegocio)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>

        <div>
          <label>Tipo de Imóvel</label>
          <select
            id="tipo_imovel"
            v-model.trim="tipo_imovel"
            class="inputtext"
            name="tipo_imovel"
          >
            <option 
              value=""
            />
            <option
              v-for="item in Object.values(tipoImovel)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.valor }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex gap-3">
        <button @click="limparFiltro" type="button" class="btnLabel">
          <font-awesome-icon
            icon="trash"
          />
          Limpar
        </button>
        <button class="btnAmarelo">
          Pesquisar
        </button>
      </div>
    </form>
    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>ID</th>
            <th>Endereço</th>
            <th>Tipo de imóvel</th>
            <th>Negócio</th>
            <th>Área privativa</th>
            <th>Data</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="item in lista"
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td class="endereco">{{ item.endereco }}</td>
            <td>{{ item.tipo_imovel }}</td>
            <td>{{ item.tipo_negocio }}</td>
            <td>{{ item.area_privativa ? item.area_privativa + 'm²' : "-" }}</td>
            <td>{{ dateToField(item.created_at) }}</td>
            <div>
              <td class="tdBtnAcao">
                <router-link
                  :to="`/opnioesMercado/${item.id}`"
                  class="icon"
                >
                  <font-awesome-icon
                    icon="pen-to-square"
                  />
                </router-link>
              </td>
            </div> 
          </tr>
          <tr v-if="chamadasPendentes.lista">
            <td colspan="3">
              <font-awesome-icon
                icon="spinner" 
                class="spinner mt-3"
              />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="3">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!lista.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="paginacao" 
      class="d-flex gap-2 justify-content-center mt-4"
    >
      <button
        v-for="(link, index) in paginacao.links.slice(1, -1)"
        :key="index"
        class="paginacao-btn"
        :disabled="!link.url"
        :class="link.active ? 'paginacaoAtiva' : ''"
        @click="mudarPagina(link)"
      >
        {{ link.label }}
      </button>     
    </div>
  </div>
</template>

<script setup>
import tipoImovel from '@/consts/tipoImovel';
import tipoNegocio from '@/consts/tipoNegocio';
import dateToField from '@/helpers/dateToFiel';
import { useOpiniaoMercadoStore } from '@/store/opiniaoMercado.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const opiniaoMercadoStore = useOpiniaoMercadoStore()
const { lista, chamadasPendentes, erro, paginacao } = storeToRefs(opiniaoMercadoStore);

const route = useRoute();
const router = useRouter();

const idBusca = ref(route.query.id)
const endereco = ref(route.query.endereco)
const bairro = ref(route.query.bairro)
const tipo_negocio = ref(route.query.tipo_negocio
? Object.keys(tipoNegocio)
  .find((x) => x.toLowerCase() === route.query.tipo_negocio.toLocaleLowerCase())
: undefined);

const tipo_imovel = ref(route.query.tipo_imovel
? Object.keys(tipoImovel)
  .find((x) => x.toLowerCase() === route.query.tipo_imovel.toLocaleLowerCase())
: undefined);


const consulta = {};
function atualizarUrl() {

  consulta.id = idBusca.value || '';
  consulta.endereco = endereco.value || '';
  consulta.neighborhood = bairro.value || '';
  consulta.business = tipo_negocio.value || '';
  consulta.type = tipo_imovel.value || '';

  router.push({
    query: consulta,
  });
}

watch([
  () => route.query.id,
  () => route.query.endereco,
  () => route.query.neighborhood,
  () => route.query.type,
  () => route.query.business,
], () => {

  const consultaAtualizada = {
    id: route.query.id || '', 
    endereco: route.query.endereco || '',
    neighborhood: route.query.neighborhood || '',
    type: route.query.type || '',
    business: route.query.business || '',
  };

  opiniaoMercadoStore.$reset();
  opiniaoMercadoStore.buscarTudo(consultaAtualizada);
}, { immediate: true });

function limparFiltro(){
  idBusca.value = '';
  endereco.value = '';
  bairro.value = '';
  tipo_negocio.value = '';
  tipo_imovel.value = '';

  router.push({
    query: {}
  });

  opiniaoMercadoStore.$reset();
  opiniaoMercadoStore.buscarTudo({});
}

async function iniciar(){
  await opiniaoMercadoStore.buscarTudo()
}
iniciar()

async function mudarPagina(link) {
  if (link){
    await opiniaoMercadoStore.mudarPagina(link.label)
  }
}
</script>

<style scoped>
.endereco{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 425px;
}
</style>