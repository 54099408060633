import { defineStore } from 'pinia';
import { useAuthStore } from '@/store/auth.store';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL2;

export const useImoveisStore = defineStore('imoveis', {
  state: () => ({
    lista: [],
    loading: false,
    erro: null
  }),
  actions: {
    async getImoveisByLocation(params = {}) {
      const userStore = useAuthStore();
      const userId = userStore.user.id;
      this.loading = true;
      this.error = null;
      try {
        const url = `${baseUrl}busca?lng=${params.location.lng}&lat=${params.location.lat}&dist=${params.radius || params.raio || params.raio_busca}&tipo=${params.tipo_imovel}&tipo_negocio=${params.tipo_negocio}&anunciante_id=${userId}`;
        const request = await this.requestS.get(url);
        this.lista = request;
        return request
      } catch (erro) {
        this.error = erro;
        console.error('Erro no fetch:', erro);
        return null;
      } finally {
        this.loading = false;
      }
    }
  }
});