import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';
import { useAuthStore } from '@/store/auth.store';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useOrcamentoStore = defineStore('Orcamento', {
  state: () => ({
    lista: [],
    emFoco: null,
    filePath: "",
    paginacao: {
      links: [],
      current_page: null,
      last_page: null,
      per_page: 20,
      total: null,
    },
    chamadasPendentes: {
      lista: false,
      emFoco: false,
      filePath: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/budgets`, {
          ...params,
          page: params.page || this.paginacao.current_page || 1,
          per_page: this.paginacao.per_page,
        });
        this.lista = request.data

        this.paginacao.current_page = request.current_page;
        this.paginacao.last_page = request.last_page;
        this.paginacao.per_page = request.per_page;
        this.paginacao.total = request.total;
        this.paginacao.links = request.links;
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },

    async mudarPagina(page) {
      this.chamadasPendentes.lista = true;
      this.erro = null;
      try {
        this.paginacao.current_page = page;
        this.buscarTudo();
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },
    
    async buscarItem(id = 0, params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/budget/${id}`);
        this.emFoco = resposta
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async excluirItem(id) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        await this.requestS.delete(`${baseUrl}/budget/${id}`);
        this.chamadasPendentes.lista = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.lista = false;
        return false;
      }
    },

    async enviarOpiniaoEmail(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.post(`${baseUrl}/budget-send/${id}`, params);
        const alertStore = useAlertStore();
        alertStore.success('Arquivo colocado para fila de envio');
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async enviarStatus(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.post(`${baseUrl}/budget-set-status/${id}`, params);
        const alertStore = useAlertStore();
        alertStore.success('Status alterado com sucesso!');
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async gerarPdf(id) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
      const authStore = useAuthStore();
      const token = authStore.token;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };
        const response = await fetch(`${baseUrl}/budget-print/${id}`, requestOptions)  
        const filePath = await response.text();
        this.filePath = filePath;   
        return this.filePath;
      } catch (erro) {
        this.erro = erro;
        console.error('Erro ao gerar pdf:', erro);
      } finally {
        this.chamadasPendentes.emFoco = false;
      }
    },

    async salvarItem(params = {}, id = null) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        if (id) {
          await this.requestS.put(`${baseUrl}/budget/${id}`, params);
        } else {
          await this.requestS.post(`${baseUrl}/budgets`, params);
        }

        this.chamadasPendentes.emFoco = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },
  },
});
