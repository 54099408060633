export default {
  "Ativo": {
    nome: 'Ativo',
    valor: 'Ativo',
  },
  "Inativa": {
    nome: 'Inativo',
    valor: 'Inativa',
  },
};
