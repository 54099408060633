<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>Amostras</h1>
      <div class="d-flex justify-content-between align-items-center gap-2">
        <button 
          class="btnCiano"
          data-bs-toggle="modal"
          data-bs-target="#modalImportarXml"
          type="button"
        >
          Importar de Excel
        </button>
        <router-link
          :to="{ name: 'Amostras criar' }"
          class="btnRoxo"
        >
          Nova amostra
        </router-link>  
      </div>
    </div>

    <strong
      style="color: var(--roxo)"
    >
      Filtrar por:
    </strong>
    <form
      class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
      @submit.prevent="atualizarUrl"
    >
      <div class="d-flex gap-3 align-items-center">
        <div class="mw-100">
          <label>Endereço</label>
          <input
            id="endereco"
            v-model.trim="endereco"
            placeholder="Endereço"
            class="inputtext"
            name="endereco"
            type="text"
          >
        </div>

        <div>
          <label>Bairro</label>
          <input
            id="bairro"
            v-model.trim="bairro"
            placeholder="Bairro"
            class="inputtext"
            name="bairro"
            type="text"
          >
        </div>

        <div>
          <label>Tipo de negócio</label>
          <select
            id="tipo_negocio"
            v-model.trim="tipo_negocio"
            class="inputtext"
            name="tipo_negocio"
          >
            <option 
              value=""
            />
            <option
              v-for="item in Object.values(tipoNegocio)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>

        <div>
          <label>Tipo de Imóvel</label>
          <select
            id="tipo_imovel"
            v-model.trim="tipo_imovel"
            class="inputtext"
            name="tipo_imovel"
          >
            <option 
              value=""
            />
            <option
              v-for="item in Object.values(tipoImovel)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.valor }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex gap-3">
        <button @click="limparFiltro" type="button" class="btnLabel">
          <font-awesome-icon
            icon="trash"
          />
          Limpar
        </button>
        <button class="btnAmarelo">
          Pesquisar
        </button>
      </div>
    </form>
    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Endereço</th>
            <th>Titulo</th>
            <th>Negócio</th>
            <th>Data de registro</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="item in lista"
            :key="item.id"
          >
            <td class="endereco">{{ item.logradouro }}</td>
            <td class="tipo">{{ item.titulo }}</td>
            <td>{{ item.tipo_negocio }}</td> 
            <td>{{ item.created_at ? item.created_at : '-'}}</td>
            <div class="d-flex">
              <td class="tdBtnAcao">
                <router-link
                  :to="{ name: 'AmostrasEditar', params: { amostraId: item._id } }"
                  class="icon"
                >
                  <font-awesome-icon
                    icon="pen-to-square"
                  />
                </router-link>
              </td>
              <td class="tdBtnAcao">
                <button
                  class="icon"
                  arial-label="excluir"
                  @click="excluirAmostra(item._id)"
                >
                  <font-awesome-icon icon="trash" />
                </button>
              </td>
            </div> 
          </tr>
          <tr v-if="chamadasPendentes.lista">
            <td colspan="3">
              <font-awesome-icon
                icon="spinner" 
                class="spinner mt-3"
              />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="3">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!lista.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-if="paginacao" 
      class="d-flex gap-2 justify-content-center mt-4"
    >
      <button
        v-for="(link, index) in paginacao.links.slice(1, -1)"
        :key="index"
        class="paginacao-btn"
        :disabled="!link.url"
        :class="link.active ? 'paginacaoAtiva' : ''"
        @click="mudarPagina(link)"
      >
        {{ link.label }}
      </button>     
    </div>
    
    <div
      id="modalImportarXml"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header justify-content-between">
            <h3>Importação de Imóveis Vendidos</h3>
            <button 
              type="button" 
              class="Modalclose"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <font-awesome-icon icon="circle-xmark" />
            </button>
          </div>
          <div class="modal-body">
            <form
              enctype="multipart/form-data"
              @submit.prevent="enviarXml"
            >
              <div class="mb-2 w-100">
                <label for="file">
                  Link para importação em formato Excel
                </label>
                <input
                  id="file"
                  name="file"
                  type="file" 
                  class="inputtext"
                  @change="onFileChange"
                >
              </div>
              <a 
                target="_blank"
                class="btnCiano px-0"
                href="https://avaliacao.avalion.com.br/excel/importacao_realizados.xlsx"
              >
                Baixar Excel no padrão esperado
              </a>
              <div class="modal-footer py-0">
                <button 
                  class="btnRoxo" 
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import tipoImovel from '@/consts/tipoImovel';
import tipoNegocio from '@/consts/tipoNegocio';
import dateToField from '@/helpers/dateToFiel';
import { useAlertStore } from '@/store/alert.store';
import { useAmostrasStore } from '@/store/amostras.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';


const alertStore = useAlertStore();
const route = useRoute();
const router = useRouter();

const amostrasStore = useAmostrasStore()
const { lista, chamadasPendentes, erro, paginacao } = storeToRefs(amostrasStore);

amostrasStore.buscarTudo()

const fileXml = ref(null)
const endereco = ref(route.query.endereco)
const bairro = ref(route.query.bairro)
const tipo_negocio = ref(route.query.tipo_negocio
? Object.keys(tipoNegocio)
  .find((x) => x.toLowerCase() === route.query.tipo_negocio.toLocaleLowerCase())
: undefined);

const tipo_imovel = ref(route.query.tipo_imovel
? Object.keys(tipoImovel)
  .find((x) => x.toLowerCase() === route.query.tipo_imovel.toLocaleLowerCase())
: undefined);


const consulta = {};
function atualizarUrl() {
  consulta.endereco = endereco.value || '';
  consulta.neighborhood = bairro.value || '';
  consulta.business = tipo_negocio.value || '';
  consulta.type = tipo_imovel.value || '';

  router.push({
    query: consulta,
  });
}

watch([
  () => route.query.endereco,
  () => route.query.neighborhood,
  () => route.query.type,
  () => route.query.business,
], () => {

  const consultaAtualizada = {
    endereco: route.query.endereco || '',
    neighborhood: route.query.neighborhood || '',
    type: route.query.type || '',
    business: route.query.business || '',
  };

  amostrasStore.$reset();
  amostrasStore.buscarTudo(consultaAtualizada);
}, { immediate: true });

function limparFiltro(){
  endereco.value = '';
  bairro.value = '';
  tipo_negocio.value = '';
  tipo_imovel.value = '';

  router.push({
    query: {}
  });

  amostrasStore.$reset();
  amostrasStore.buscarTudo({});
}

async function excluirAmostra(id) {
  alertStore.confirmAction('Deseja mesmo remover esse item?', async () => {
    if (await amostrasStore.excluirItem(id)) {
      amostrasStore.$reset();
      amostrasStore.buscarTudo();
      alertStore.success('Amostra removida.');
    }
  }, 'Remover');
}

const onFileChange = (event) => {
  fileXml.value = event.target.files[0];
};

async function enviarXml(){
  if (!fileXml.value) {
    alertStore.error('Por favor, selecione um arquivo.');
    return;
  }
  try{
    const formData = new FormData();
    formData.append('file', fileXml.value);
    await amostrasStore.enviarXml(formData)
    alertStore.success('Arquivo enviado com sucesso!');
  } catch(erro){
    alertStore.error('Erro ao enviar o arquivo: ' + erro.message); 
  }
}

async function mudarPagina(link) {
  if (link){
    await amostrasStore.mudarPagina(link.label)
  }
}
</script>

<style scoped>
.endereco, 
.tipo{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
}

.endereco{
  max-width: 230px;
}

.tipo{
  max-width: 150px;
}
</style>