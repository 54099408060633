<template>
  <div>
    <section class="card-branco">
      <div class="d-flex justify-content-between gap-3 pb-4 align-items-center">
        <h1>
          Seja bem-vindo(a) ao Avalion, pronto para revolucionar
          o mercado conosco?
        </h1>
        <div>
          <MenuUsuario />
        </div>
      </div>
      <div class="d-flex justify-content-between gap-3">
        <div class="trabalho-realizado d-flex gap-3">
          <div 
            class="d-flex gap-3 align-items-center card-cinza"
            @click="visible = !visible"
          >
            <span style="color: var(--roxo)">
              {{ totalOnMonth }}
            </span>
            <div>
              <p>trabalhos realizados este mês por você</p>
              <CCollapse :visible="visible">
                <CCard class="my-3">
                  <CCardBody class="p-0">
                    <div>
                      <p class="pb-1">
                        Analise de mercado: {{ emFoco?.getCountRequestsAnalise }}
                      </p>
                      <p class="pb-1">
                        Opinião de mercado: {{ emFoco?.getCountRequestsOpiniao }}
                      </p>
                      <p class="pb-1">
                        Pesquisa inteligente: {{ emFoco?.getCountRequestsPic }}
                      </p>
                      <p class="pb-1">
                        PTAMs{{ emFoco?.getCountRequestsPtam }}
                      </p>
                    </div>
                  </CCardBody>
                </CCard>
              </CCollapse>
            </div>
          </div>
          <!-- <div class="d-flex gap-2 card-cinza align-items-center">
            <span style="color: var(--ciano)">20</span>
            <p>trabalhos realizados este mês por usuário</p>
          </div> -->
        </div>
        <div 
          v-if="planoAtual != null && expiraEmCincoDias"
          class="data-expiracao d-flex align-items-center">
          <div class="text-end">
            <p>*Fique atento! Seu plano expira em</p>
            <span>{{ dateToField(planoAtual.data_expiracao)}}</span>
          </div>
        </div>
      </div>
    </section>
    <section class="d-flex mt-2 gap-2">
      <div class="card-link card-roxo align-items-center justify-content-center d-flex">
        <a
          href="https://wa.me/554196931369?text=Ol%C3%A1,%20Preciso%20de%20suporte."
          class="align-items-center justify-content-end gap-3 d-flex"
        >
          <h1>Fale com o suporte</h1>
          <font-awesome-icon icon="arrow-right" />
        </a>
      </div>
      <div class="card-link card-ciano">
        <h1>Vamos fazer um upgrade?</h1>
        <router-link
          to="/upgrade"
          class="align-items-center justify-content-end gap-3 d-flex"
        >
          Contrate
          <font-awesome-icon icon="arrow-right" />
        </router-link>
      </div>
    </section>
    <div class="d-flex mt-2 gap-2">
      <PesquisaInteligenteCollapseTable />
      <OpiniaoMercadoCollapseTable />
    </div>
    <div class="d-flex mt-2 gap-2">
      <UsuariosCollapseTable />
      <HistoricoFinanceiroCollapseTable />
    </div>
    <div class="d-flex mt-2 gap-2">
      <PacotesAdquiridosCollapseTable />
    </div>

    <div 
      v-if="pesquisaAtiva && modal" 
      class="modal-dialog modal-dialog-centered modal-overlay"
    >
      <div class="modal-content align-items-end">
        <button 
          class="Modalclose" 
          type="button"
          @click="closeModal"
        >
          <font-awesome-icon icon="circle-xmark" />
        </button>
        <div class="modal-content">
          <img src="../../assets/imagens/pesquisa.png">
        </div>
        <div v-if="chamadasPendentes.pesquisaAtiva">
          <font-awesome-icon
            icon="spinner" 
            class="spinner mt-3"
          />
        </div>
        <div 
          v-else 
          class="btnGrupo d-flex gap-3"
        >
          <button
            v-if="!pesquisaAtiva?.required" 
            class="Modalclose" 
            type="button"
            @click="naoExibir"
          >
            Não exibir novamente
          </button>

          <router-link
            v-if="pesquisaAtiva?.id"
            :to="{ 
              name: 'Responder',
              params: { idPesquisa: pesquisaAtiva?.id } 
            }"
            class="btnRoxo"
          >
            Nova pesquisa
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import HistoricoFinanceiroCollapseTable from '@/components/collapseTable/HistoricoFinanceiroCollapseTable.vue';
import OpiniaoMercadoCollapseTable from '@/components/collapseTable/OpiniaoMercadoCollapseTable.vue';
import PacotesAdquiridosCollapseTable from '@/components/collapseTable/PacotesAdquiridosCollapseTable.vue';
import PesquisaInteligenteCollapseTable from '@/components/collapseTable/PesquisaInteligenteCollapseTable.vue';
import UsuariosCollapseTable from '@/components/collapseTable/UsuariosCollapseTable.vue';
import MenuUsuario from '@/components/MenuUsuario.vue';

import dateToField from '@/helpers/dateToFiel';
import { useAuthStore } from '@/store/auth.store';
import { useClientesStore } from '@/store/clientes.store';
import { usePesquisaStore } from '@/store/pesquisas.store';
import { differenceInDays } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';

const authStore = useAuthStore();
const { user } = storeToRefs(authStore)

const meusClientesStore = useClientesStore()
const { planoAtual, emFoco } = storeToRefs(meusClientesStore);

const pesquisaStore = usePesquisaStore()
const { pesquisaAtiva, chamadasPendentes } = storeToRefs(pesquisaStore);

const visible = ref(false)
const modal = ref(false)

function closeModal() {
  modal.value = false;
}

const expiraEmCincoDias = computed(() => {
  if (!planoAtual.value || !planoAtual.value.data_expiracao) return false;

  const dataExpiracao = new Date(planoAtual.value.data_expiracao);
  const hoje = new Date();
  const diasRestantes = differenceInDays(dataExpiracao, hoje);

  return diasRestantes <= 5 && diasRestantes >= 0;
});

const totalOnMonth = computed(() => {
  if (!emFoco.value) return 0;

  return Object.keys(emFoco.value)
    .filter(key => key.endsWith("OnMonth"))
    .reduce((sum, key) => sum + emFoco.value[key], 0);
});

function naoExibir() {
  if (pesquisaAtiva.value?.id) {
    const localStorageKey = `nãoExibir_${pesquisaAtiva.value.id}`;
    localStorage.setItem(localStorageKey, 'true');
    modal.value = false;
  }
}

watch(
  () => pesquisaAtiva.value?.id,
  (newId) => {
    if (newId) {
      const localStorageKey = `nãoExibir_${newId}`;
      const naoExibir = localStorage.getItem(localStorageKey);
      modal.value = naoExibir !== 'true';
    }
  },
  { immediate: true }
);

async function iniciar(){
  if (user.value?.id){
    await meusClientesStore.buscarPlanoAtual(user.value?.id)
    await meusClientesStore.buscarItem(user.value?.id)
  }
  await pesquisaStore.buscarPesquisaAtiva()
}

onMounted(() => {
  iniciar();
})

</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.btnGrupo, .spinner {
  position: absolute;
  bottom: 25px;
  left: 110px;
  text-align: center;
  margin: 0;
}

.spinner {
  bottom: 52px;
  left: 251px;

}

.modal-content {
  width: 625px;
  max-width: 100%;
  position: relative;
}

h1{
  color: #5F5F5F;
  font-weight: 500;
}

.card-cinza{
  background-color: var(--cinzaClaro);
  padding: 1rem;
  max-width: 30rem;
  border-radius: 0.875rem;
}

.trabalho-realizado span{
  font-size: 3.75rem;
  font-weight: 500
}

.trabalho-realizado p{
  font-size: 1.13rem;
  font-weight: 500
}

.data-expiracao span{
  color: #ee3b2b;
  font-weight: 500;
}

.data-expiracao{
  border: 2px solid #E5BA38;
  padding: 0 1rem;
  max-width: 22.625rem;
  border-radius: 0.875rem;
}

.card-roxo{
  background-image: url(../../assets/imagens/bgRoxo.jpg);
}

.card-ciano{
  background-image: url(../../assets/imagens/bgCiano.jpg);
}

.card-link{
  color: var(--branco);
  width: 100%;
  padding: 20px;
  border-radius: 32px;
}

.card-link h1{
  color: var(--branco);
}

.card-link a{
  color: var(--branco);
  text-wrap: nowrap;
  font-size: 1.25rem;
}

.card-link svg{
  max-width: 20px;
}

.card {
  background: none;
  border: none;
}
</style>