<template>
  <div class="py-3 px-3 bordaAmarela mb-3">
    <p>
      A avaliação de terrenos exige uma análise cautelosa por parte 
      do Corretor/Avaliador relacionadas às características específicas deste tipo de imóvel.
    </p>
  </div>
  <div class="py-3 px-3 bordaAmarela mb-3">
    <p>
      O Avalion faz a sua parte apresentando as amostras disponíveis na região pesquisada,
      similares ao imóvel avaliando, baseadas nas informações fornecidas e nos filtros
      aplicados pelo usuário. Cabe agora o Corretor/Avaliador realizar uma análise detalhada
      do imóvel avaliando e das amostras, aplicando de fato o Método Comparativo Direto de Dados de Mercado.
    </p>
  </div>
  <div class="py-3 px-3 bordaAmarela mb-3">
    <p>
      Fatores como zoneamento, sistema viário, testada, potencial construtivo, viabilidade,
      dentro outros, devem ser analisados e comparados como os imóveis das amostras.
      Muito importante a análise detalhada da Guia Amarela (Parâmetros Urbanísticos
      disponibilizados pela Prefeitura de sua cidade) do imóvel avaliando e das amostras
      disponibilizadas, para daí atribuir o valor de mercado do imóvel avaliando.
    </p>
  </div>
  <form
    :validation-schema="schema"
  >
    <div class="mb-4">  
      <GMapMap 
        :center="coords"
        :zoom="15" 
        map-type-id="terrain" 
        style="width: 100%; 
        height: 22rem"
      >
        <GMapMarker
          :key="markerDetails.id"
          :position="markerDetails.position"
          :clickable="true"
          :draggable="false"
        />
        <GMapCircle 
          :center="coords" 
          :radius="parseInt(raio_busca)" 
          :options="{ 
            fillColor: '#FF0000', 
            fillOpacity: 0.2, 
            strokeColor: '#FF0000', 
            strokeOpacity: 0.5
          }"
        />
        <div v-if="lista.length">
          <GMapMarker
            v-for="imovel in markers"
            :key="imovel._id"
            :position="{ 
              lat: imovel.location.coordinates[1], 
              lng: imovel.location.coordinates[0] 
            }"
            :clickable="true"
            :draggable="false"
            @click="openMarker(imovel._id)"
          >
            <GMapInfoWindow
              :closeclick="true"
              :opened="openedMarkerID === imovel._id"
              class="infoImovel"
              @closeclick="openMarker(null)"
            >
              <div>
                <p class="pb-3">{{imovel.titulo}}</p>
                <a target="_blank" :href="imovel.anuncioUrl">Ver anuncio</a>
              </div>
            </GMapInfoWindow>
          </GMapMarker>
        </div>
      </GMapMap>
    </div>

    <div class="mb-2 d-flex align-items-center">
      <span v-if="loading">
        <font-awesome-icon
          icon="spinner" 
          class="spinner mt-3"
        />
      </span>
      <div class="mb-3 w-100">
        <LabelFromYup
          name="raio_busca"
          :schema="schema"
        />
        <Field
          v-model="raio_busca"
          name="raio_busca"
          as="select"
          class="inputtext mb1"
        >
          <option value="">
            Selecionar
          </option>
          <option
            v-for="item in Object.values(raioBusca)"
            :key="item.value"
            :value="item.valor"
          >
            {{ item.nome }}
          </option>
        </Field>
      </div>
    </div>

    <div class="d-flex w-100 my-2 mb-3">
      <Field
        v-model="exibir_amostras_na_rua"
        name="exibir_amostras_na_rua"
        type="checkbox"
        class="inputcheckbox"
        :value="true"
        @change="filtrarImoveisPorRua"
      />
      <LabelFromYup
        name="exibir_amostras_na_rua"
        :schema="schema"
        class="ps-2"
      />
    </div>
  </form>
  <div>
    <strong
      class="pb-2 d-block"
      style="color: var(--roxo)"
    >
      Filtrar por:
    </strong>
    <form
      class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
    >
      <div class="d-flex gap-3 align-items-center flex-wrap">
        <div class="d-flex gap-3 align-items-center w-100">
          <div class="w-100">
            <label>Área Mínima</label>
            <input
              id="area_min"
              v-model.trim="area_min"
              placeholder="Área Mínima"
              class="inputtext"
              name="area_min"
              type="number"
            >
          </div>
          <div class="w-100">
            <label>Área Máxima</label>
            <input
              id="area_max"
              v-model.trim="area_max"
              placeholder="Área Máxima"
              class="inputtext"
              name="area_max"
              type="number"
            >
          </div>
          <div class="w-100">
            <label>Valor Mínimo</label>
            <input
              id="valor_min"
              v-model.trim="valor_min"
              placeholder="Valor Mínimo"
              class="inputtext"
              name="valor_min"
              type="text"
            >
          </div>
          <div class="w-100">
            <label>Valor Máximo</label>
            <input
              id="valor_max"
              v-model.trim="valor_max"
              placeholder="Valor Máximo"
              class="inputtext"
              name="valor_max"
              type="text"
            >
          </div>
        </div>
        <div class="d-flex gap-3 align-items-center w-100">
          <div class="w-50">
            <label>Dormitórios</label>
            <div class="d-flex gap-3 align-items-center">
              <div>
                <input
                  id="1"
                  v-model="quartos"
                  value="1"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">1</label>
              </div>
              <div>
                <input
                  id="2"
                  v-model="quartos"
                  value="2"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">2</label>
              </div>
              <div>
                <input
                  id="3"
                  v-model="quartos"
                  value="3"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">3</label>
              </div>
              <div>
                <input
                  id="4"
                  v-model="quartos"
                  value="4"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">4</label>
              </div>
            </div>
          </div>
          <div class="w-50">
            <label>Vagas de garagem</label>
            <div class="d-flex gap-3 align-items-center">
              <div>
                <input
                  id="1"
                  v-model="garagem"
                  value="1"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">1</label>
              </div>
              <div>
                <input
                  id="2"
                  v-model="garagem"
                  value="2"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">2</label>
              </div>
              <div>
                <input
                  id="3"
                  v-model="garagem"
                  value="3"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">3</label>
              </div>
              <div>
                <input
                  id="4"
                  v-model="garagem"
                  value="4"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">4</label>
              </div>
            </div>
          </div>
          <div class="w-100">
            <label>Endereço</label>
            <input
              id="endereco"
              v-model="endereco"
              placeholder="Endereço"
              class="inputtext"
              name="endereco"
              type="text"
            >
          </div>
        </div>
      </div>
    </form>
    <button  
      type="button" 
      class="btnRoxo"
      @click="limparFiltro"
    >
      Limpar filtro
    </button>

    <div class="w-100 pt-3">
      <input
        id="selectAll"
        class="me-2" 
        type="checkbox" 
        :checked="allSelected"
        @change="toggleSelectAll"
      >
      <label>Selecionar todas amostras</label>
    </div>

    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th />
            <th 
              class="ordenagem"
              @click="sortTable('logradouro' || 'bairro')"
            >
              Endereço/Bairro
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('preco')"
            >
              Valor
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('area')"
            >
              Á. Priv.
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('area_total')"
            >
              Á. Total
            </th>
            <th>
              R$/m² priv.
            </th>
            <th>
              R$/m² total
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('quartos')"
            >
              Quartos
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('vagas')"
            >
              Vagas
            </th>
            <th 
              class="ordenagem"
              @click="sortTable('anunciante')"
            >
              Anunciante
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in listaOrdenada"
            :key="item.id"
          >
            <td>
              <input 
                id="imovel_selecionado"
                type="checkbox"
                name="imovel_selecionado"
                :checked="checkboxStates[item._id] || false"
                @click="selecionaImovel(item._id, $event)"
              >
            </td>
            <td class="anunciante">{{ item.logradouro || item.bairro }}</td>
            <td>R${{ dinheiro(item.preco) }}</td>
            <td>{{ item.area_util + `m²` }}</td>
            <td>{{ item.area_total + `m²` }}</td>
            <td>R${{ dinheiro(item.preco / item.area_util) }}</td>
            <td>R${{ dinheiro(item.preco / item.area_total) }}</td>
            <td>{{ item.quartos }}</td>
            <td>{{ item.vagas }}</td>
            <td class="anunciante">{{ item.anunciante }}</td>
            <td class="tdBtnAcao">
              <button 
                data-bs-toggle="modal" 
                data-bs-target="#itemModal"
                class="icon" 
                @click="selecionaItem(item)"
              >
                <font-awesome-icon icon="pen-to-square" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div 
      v-if="amostrasSelecionadas.length"
      class="py-3 px-3 bordaAmarela w-50 my-5 mx-auto"
    >
      <p>
        {{ amostrasSelecionadas.length }} amostras selecionadas
      </p>
    </div>

    <div class="d-flex flex-column align-items-center mt-4">
      <button 
        :class="{'disabled': requisicaoNavegacao == '0', 'btnAmarelo': true}"  
        class="btnAmarelo"
        @click="salvarAmostras"
      >
        Salvar alteração
      </button>
    </div>

    <div class="modal fade" id="itemModal" tabindex="-1" aria-labelledby="itemModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content" style="width: 100%;">
          <div class="modal-header">
            <h5 class="modal-title" id="itemModalLabel">{{ selectedItem?.titulo || 'Detalhes do Imóvel' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="imovel d-flex gap-5 mt-4">
              <ul>
                <li>Tipo<strong>{{ selectedItem?.tipo }}</strong></li>
                <li>Preço<strong>{{ `R$${dinheiro(selectedItem?.preco)}` }}</strong></li>
                <li>Aréa total<strong>{{ selectedItem?.area_total }}</strong></li>
                <li>Aréa útil<strong>{{ selectedItem?.area_util }}</strong></li>
                <li>Quartos<strong>{{ selectedItem?.quartos }}</strong></li>
                <!-- <li>Banheiro<strong>{{ selectedItem?.quartos }}</strong></li> -->
                <li>Suites<strong>{{ selectedItem?.suites }}</strong></li>
                <li>Vagas<strong>{{ selectedItem?.vagas }}</strong></li>
                <li>IPTU<strong>{{ selectedItem?.iptu }}</strong></li>
              </ul>
              <ul>
                <li>Condominio<strong>{{ selectedItem?.condominio }}</strong></li>
                <li>Anunciante<strong>{{ selectedItem?.anunciante }}</strong></li>
                <li>
                  Link do Anuncio
                  <strong>
                    <a
                      :href="selectedItem?.anuncioUrl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Veja o anuncio completo</a>
                  </strong>
                </li>
                <li>Ano de construção<strong></strong></li>
                <li>Data da criação<strong>{{ dataFormatada(selectedItem?.createdAt) }}</strong></li>
                <li>Data da atualização<strong>{{ dataFormatada(selectedItem?.updatedAt) }}</strong></li>
              </ul>
            </div>
            <div>
              <strong>Descrição</strong>
              <p 
                v-html="selectedItem?.descricao"
              />
            </div>
            <div 
              v-if="selectedItem?.fotos"
              class="mt-4"
            >
              <h1 class="pb-2">Imagens</h1>
              <div class="d-flex gap-3 flex-wrap">
                <div
                  v-for="(foto, index) in selectedItem?.fotos"
                  :key="index"
                  class="imagem-wrapper"
                >
                  <img
                    :src="foto"
                    alt="Imagem do Imóvel"
                    class="imagem-imovel"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { amostraPtams as schema } from '@/consts/formSchema';
import raioBusca from '@/consts/raioBusca';
import dataFormatada from '@/helpers/dataFormatada';
import dinheiro from '@/helpers/dinheiro';
import { useAlertStore } from '@/store/alert.store';
import { useImoveisStore } from '@/store/buscaImoveisPorLocalizacao';
import { usePtamsStore } from '@/store/ptams.store';
import { useRequisicaoFinalidaeStore } from '@/store/requisicoesFinalidade.store';
import { Tab } from 'bootstrap';
import { storeToRefs } from 'pinia';
import { Field, useForm } from 'vee-validate';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const alertStore = useAlertStore();
const router = useRouter();

const imoveisStore = useImoveisStore()
const { lista, loading } = storeToRefs(imoveisStore);

const ptamsStore = usePtamsStore()
const { infoImovel, imovelId } = storeToRefs(ptamsStore);

const requisicoesFinalidadeStore = useRequisicaoFinalidaeStore()
const { requisicaoNavegacao } = storeToRefs(requisicoesFinalidadeStore);  

const exibir_amostras_na_rua = ref(false)
const checkboxStates = reactive({});
const selectedItem = ref(null);
const coords = ref({lat: '', lng: ''});
const markerDetails = ref({ id: 1, position: coords.value})
const markers = computed(() => listaCompleta.value);
const openedMarkerID = ref(null)
const raio_busca = ref('');
const area_min = ref('');
const area_max = ref('');
const valor_min = ref('');
const valor_max = ref('');
const endereco = ref('');
const quartos = ref([])
const garagem = ref([])
const listaOrdenada = ref([]);
const colunaOrdenada = ref(null);
const direcaoOrdenada = ref(null);

const props = defineProps({
  ptamsId: {
    type: Number,
    default: 0,
  },
});

const {
  values, handleSubmit, resetForm
} = useForm({
  initialValues: infoImovel.value,
  validationSchema: schema,
});

function calcularCentroDosImoveis() {
  if (!lista.value.length) return;

  const latitudes = lista.value.map(imovel => imovel.location.coordinates[1]);
  const longitudes = lista.value.map(imovel => imovel.location.coordinates[0]);

  const mediaLatitude = latitudes.reduce((total, lat) => total + lat, 0) / latitudes.length;
  const mediaLongitude = longitudes.reduce((total, lng) => total + lng, 0) / longitudes.length;

  coords.value = { lat: mediaLatitude, lng: mediaLongitude };
}

function openMarker(id) {
  openedMarkerID.value = id
}

const inforacoesImovel = computed(() => {
  if (!infoImovel.value) {
    return {};
  }

  return {
    lat: infoImovel?.value.latitude || '',
    lng: infoImovel?.value.longitude || '',
    tipo_negocio: infoImovel?.value.tipo_negocio || '',
    raio_busca: infoImovel?.value.raio_busca !== null ? infoImovel?.value.raio_busca : '100',
    tipo_imovel: infoImovel?.value.tipo_imovel || '',
    endereco: infoImovel?.value.endereco || '',
  };
});

async function postContador(){
  try{
    const contador = {
       location: {
         lat: infoImovel.value?.latitude,
         lng: infoImovel.value?.longitude
       },
       tipo_de_busca: 'ptam',
       raio_busca: raio_busca.value || infoImovel.value?.raio_busca || '100',
       endereco: infoImovel.value?.endereco,
       tipo_imovel: infoImovel.value?.tipo_imovel,
       tipo_negocio: infoImovel.value?.tipo_negocio,
    }

    await requisicoesFinalidadeStore.contador(contador)
  } catch (error) {
    alertStore.error(error);
  }
}

const getImoveis = handleSubmit(async (values) => {
  try{
    const params = {
      ...values,
      location: {
        lat: inforacoesImovel.value.lat,
        lng: inforacoesImovel.value.lng
      },
      tipo_negocio: inforacoesImovel.value.tipo_negocio,
      tipo_imovel: inforacoesImovel.value.tipo_imovel,
      raio_busca: raio_busca.value || inforacoesImovel.value.raio_busca || '100'
    }
    
    const resposta = await imoveisStore.getImoveisByLocation(params)
    if( resposta.length >= 10){
      postContador()
    }

  } catch (error) {
    alertStore.error(error);
  }
})

function filtrarImoveisPorRua() {
  if (exibir_amostras_na_rua.value) {
    lista.value = lista.value.filter(imovel => 
      imovel.logradouro &&
      imovel.logradouro.toLowerCase() === inforacoesImovel?.value.endereco.toLowerCase()
    );
  } else {
    getImoveis();
  }
}

const listaCompleta = computed(() => {
  const amostras = infoImovel.value?.amostras || [];

  const amostrasMapped = amostras.map(amostra => {
    return {
      ...amostra,
      _id: amostra.id,
      isAmostra: true,
      location: {
        coordinates: [
          parseFloat(amostra.longitude || 0), 
          parseFloat(amostra.latitude || 0)
        ]
      },
      preco: parseFloat(amostra.valor_imovel) || 0,
      area_util: parseFloat(amostra.area_util) || 0,
      area_total: parseFloat(amostra.area_total) || 0,
      logradouro: amostra.endereco || '',
      bairro: amostra.bairro || '',
    };
  });

  return [...amostrasMapped, ...lista.value];
});

const listaFiltrada = computed(() => {
  return listaCompleta.value.filter(item => {
    const areaMatch =
      (!area_min.value || item.area >= area_min.value) &&
      (!area_max.value || item.area <= area_max.value);
    
    const valorMatch =
      (!valor_min.value || item.preco >= parseFloat(valor_min.value.replace(/\D/g, ''))) &&
      (!valor_max.value || item.preco <= parseFloat(valor_max.value.replace(/\D/g, '')));
    
    const quartosMatch =
      quartos.value.length === 0 ||
      quartos.value.includes(item.quartos ? item.quartos.toString() : '');
    
    const garagemMatch =
      garagem.value.length === 0 ||
      garagem.value.includes(item.vagas ? item.vagas.toString() : '');
    
    const enderecoMatch =
      !endereco.value ||
      item.logradouro?.toLowerCase().includes(endereco.value.toLowerCase()) ||
      item.bairro.toLowerCase().includes(endereco.value.toLowerCase());
    return areaMatch && valorMatch && quartosMatch && garagemMatch && enderecoMatch;
  });
});

function limparFiltro() {
  area_min.value = '';
  area_max.value = '';
  valor_min.value = '';
  valor_max.value = '';
  endereco.value = '';
  quartos.value = [];
  garagem.value = [];
}

const amostrasSelecionadas = computed(() => {
  return listaCompleta.value.filter(imovel => checkboxStates[imovel._id]);
});

const allSelected = computed(() => {
  return listaFiltrada.value.length > 0 && listaFiltrada.value.every(imovel => checkboxStates[imovel._id]);
});

function toggleSelectAll() {
  const newValue = !allSelected.value;
  listaFiltrada.value.forEach(imovel => {
    checkboxStates[imovel._id] = newValue;
  });
}

function selecionaItem(item) {
  selectedItem.value = item;
}

function selecionaImovel(id, event) {
  checkboxStates[id] = event.target.checked;
}

function sortTable(column) {
  if (colunaOrdenada.value === column) {
    direcaoOrdenada.value = direcaoOrdenada.value === 'asc' ? 'desc' : 'asc';
  } else {
    colunaOrdenada.value = column;
    direcaoOrdenada.value = 'asc';
  }

  listaOrdenada.value = listaFiltrada.value.slice().sort((a, b) => {
    let valueA = a[column];
    let valueB = b[column];

    if (typeof valueA === 'string') {
      valueA = valueA.toUpperCase();
      valueB = valueB.toUpperCase();
    }

    if (direcaoOrdenada.value === 'asc') {
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    } else {
      return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
    }
  });
}

async function salvarAmostras(){
  try{
    const payload = {
      amostras: [
        ...amostrasSelecionadas.value
      ],
      "imovel_id": imovelId.value || props.ptamsId
    };

    const resposta = await ptamsStore.salvarAmostras(payload)

    if(resposta){
      await ptamsStore.buscarLaudo(imovelId.value || props.ptamsId)
      alertStore.success('Sucesso');
      const tabElement = document.getElementById('calculo');
      const tabTrigger = new Tab(tabElement);
      tabTrigger.show();
    }

  } catch (error) {
    alertStore.error(error);
  }
}

async function iniciar(){
  await requisicoesFinalidadeStore.buscarTudo('ptam')
}
iniciar()

watch(listaFiltrada, () => {
  listaOrdenada.value = listaFiltrada.value;
}, { immediate: true });

watch(lista, () => {
  calcularCentroDosImoveis();
});

watch(listaCompleta, (newList) => {
  newList.forEach(imovel => {
    if (imovel.isAmostra && checkboxStates[imovel._id] === undefined) {
      checkboxStates[imovel._id] = true;
    }
  });
}, { immediate: true });

watch(raio_busca, (newRadius, oldRadius) => {
  if (newRadius && newRadius !== oldRadius) {
    getImoveis();
  }
});

watch(inforacoesImovel, (newValue) => {
  if (newValue.lat && newValue.lng) {
    const raioBuscaValido = newValue.raio_busca !== null ? newValue.raio_busca : '100';
    raio_busca.value = raioBuscaValido;
    getImoveis();
  }
}, { immediate: true });

onMounted(() => {
  setTimeout(() => {
    lista.value = [];
  }, 50);
});
</script>

<style scoped>
.GoogleMap {
    height: -moz-max-content;
    height: max-content;
    background: initial;
}

.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}

.fa-file-lines {
  color: var(--branco);
  font-size: 68px;
}

.aluguel_opiniao li{
  list-style: none;
  color: var(--cinzaEscuro);
  text-decoration: none;
}

.aluguel_opiniao{
  padding: 20px;
  border-radius: 7px;
  background-color: var(--cinzaClaro);
}

.anunciante{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 175px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  width: 625px;
  max-width: 100%;
  position: relative;
}

.imagem-wrapper {
    width: 250px;
}

.ordenagem:hover::after {
  content: '▼';
  font-size: 12px;
  padding-left: 2px;
}

.tabela {
  max-height: 500px;
  overflow-y: auto;
}

.tabela::-webkit-scrollbar {
  width: 5px;
}

.tabela::-webkit-scrollbar-thumb {
  background-color: var(--roxo);
}

.tabela::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.tabela thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.infoImovel {
    max-width: 250px;
}

</style>