<template>
  <div class="card-branco mt-2 w-100">
    <div style="width:100%; max-width:500px;">
      <h1>Amostras</h1>
      <GChart
        type="GeoChart"
        :data="chartData"
        :options="chartOptions"
        :settings="{ packages: ['geochart'] }"
      />
    </div>
    <div>
      <table class="w-100 mb-3">
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Estado</th>
            <th>Quantidade</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="item in props.listaAmostrasEstado"
            :key="item.id"
          >
            <td>{{ item.estado }}</td>
            <td>{{ item.counter }}</td>
            <td
              class="tdBtnAcao">
              <button
                class="icon"
                @click="gerarExcel(item.estado)"
              >
                <font-awesome-icon icon="file-pdf" />
              </button>
            </td>
          </tr>
          <tr v-if="!props.listaAmostrasEstado?.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="chamadasPendentes.estado">
        <font-awesome-icon
          icon="spinner" 
          class="spinner mt-3"
        />
        Carregando
      </p>
    </div>
  </div>
</template>

<script setup>
import { useAlertStore } from '@/store/alert.store';
import { useDashboardAdmStore } from '@/store/dasboardAdm.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { GChart } from 'vue-google-charts';

const alertStore = useAlertStore();

const dasboardAdmStore = useDashboardAdmStore()
const { chamadasPendentes } =  storeToRefs(dasboardAdmStore)

const props = defineProps({
  listaAmostrasEstado:{
    type: Array,
    default: () => []
  }
})

const chartData = ref([
  ['State', 'Value'],
]);

const chartOptions = ref({
  region: 'BR',
  resolution: 'provinces',
  colorAxis: { 
    colors: ['#FFDEAD', '#D2691E'] 
  },
  backgroundColor: { 
    fill: 'transparent',
     stroke: 'transparent', 
     strokeWidth: 0
    },
  datalessRegionColor: 'transparent',
  displayMode: 'regions',
  keepAspectRatio: true,
});

async function gerarExcel(id) {
  try {
    if (id) {
      const resposta = await dasboardAdmStore.enviarEmail(id)
      if(resposta.status){
        alertStore.success(resposta.status);
      }
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

watch(() => props.listaAmostrasEstado, (newAmostras) => {
  if (newAmostras && newAmostras.length > 0) {
    const updatedChartData = [
      ['State', 'Value'],
      ...newAmostras.map(item => [`BR-${item.estado}`, item.counter])
    ];
    chartData.value = updatedChartData;
  }
}, { immediate: true });
</script>

