<template>
  <div class="card-branco">
    <router-link
      to="/bairros"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <h1 class="mb-2">
      {{ route?.meta?.título }}
    </h1>
    <div class="d-flex gap-4 mb-4">
      <div 
        v-if="emFoco?.mapa"
        class="imagem-imovel"
      >
        <img :src=emFoco?.mapa>
      </div>
    </div>

    <form
      @submit.prevent="onSubmitBairro"
    >
      <div class="form-group w-100">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="estado" 
            :schema="schema"
          />
          <Field 
            name="estado" 
            as="select"
            class="inputtext"
            :class="{ 'error': errors.estado }"
            @change="getCidade"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(estadosDoBrasil)"
              :key="item.nome"
              :value="item.nome"
            >
              {{ item.nome }} - {{ item.sigla }}
            </option>
          </Field>
          <ErrorMessage 
            class="error-msg"
            name="estado"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="cidade" 
            :schema="schema"
          />
          <Field 
            name="cidade" 
            as="select"
            class="inputtext"
            :class="{ 'error': errors.cidade }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in listaCidade"
              :key="item.nome"
              :value="item.nome"
            >
              {{ item.nome }} 
            </option>
          </Field>
          <ErrorMessage 
            class="error-msg"
            name="cidade"
          />
        </div>
      </div>

      <div class="form-group w-100">
        <div class="mb-2">
          <LabelFromYup 
            name="nome" 
            :schema="schema"
            :required="true"
          />
          <Field
            name="nome"
            placeholder="Bairro"
            type="text"
            class="inputtext mb-2"
            :class="{ 'error': errors.nome }"
          />
          <ErrorMessage
            class="error-msg"
            name="nome"
          />
        </div>

        <div class="mb-2">
          <LabelFromYup 
            name="fonte" 
            :schema="schema"
            :required="true"
          />
          <Field 
            name="fonte"
            placeholder="Fonte da informação" 
            type="text" 
            class="inputtext mb-2"
            :class="{ 'error': errors.fonte }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="fonte"
          />
        </div>
      </div>

      <div>
        <label>Contexto urbano <span class="required">*</span></label>
        <QuillEditor
          id="contexto_urbano"
          v-model:content="contexto_urbano"
          content-type="html"
          theme="snow"
          class="mb-3"
          name="contexto_urbano"
        />   
      </div>

      <div class="mb-2 w-100">
        <label for="mapa">Mapa ou Fotos do bairro</label>
        <input
          id="mapa"
          name="mapa"
          type="file" 
          class="inputtext mb-2"
          multiple
          @change="onFileChange"
        >
        <small>Arquivos válidos: JPG, PNG e GIF</small>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          salvar
        </button>
        <font-awesome-icon
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import estadosDoBrasil from '@/consts/estadosDoBrasil';
import { novoBairro as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { useBairroStore } from '@/store/bairro.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();
const props = defineProps({
  bairroId: {
    type: Number,
    default: 0,
  },
});

const bairroStore = useBairroStore()
const { emFoco, listaCidade } = storeToRefs(bairroStore);
const alertStore = useAlertStore();

const contexto_urbano = ref('');
const mapa = ref('');

const {
  errors, resetForm, handleSubmit, values
} = useForm({
  initialValues: emFoco.value,
  validationSchema: schema,
});

function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

async function onFileChange(e) {
  const file = e.target.files[0];
  if (file) {
    try {
      mapa.value = await toBase64(file);
    } catch (error) {
      alertStore.error("Erro ao converter a imagem para base64");
    }
  }
}

async function getCidade(e) {
  try{
    const estado = e.target.value
    await bairroStore.buscarCidade(estado)
  } catch( error){
    alertStore.error(error);
  }
}

const onSubmitBairro = handleSubmit(async (values) => {
  try{
    const payload ={
      ...values,
      contexto_urbano: contexto_urbano.value || emFoco.contexto_urbano,
      mapa: mapa.value || emFoco.mapa
    }
      
    const msg = props.bairroId
      ? 'Dados salvos com sucesso!'
      : 'Item adicionado com sucesso!';

    const resposta = props.bairroId
       ? await bairroStore.salvarItem(payload, props.bairroId)
       : await bairroStore.salvarItem(payload);  

    if (resposta) {
        alertStore.success(msg);
        bairroStore.$reset();
        router.push({ name: "bairros" });
      }
  } catch (error) {
    alertStore.error(error);
  }
})

if (props.bairroId) {
  bairroStore.buscarItem(props.bairroId);
}

watch(emFoco, (novoValor) => {
  if (novoValor) {
    resetForm({ values: novoValor });
    contexto_urbano.value = novoValor.contexto_urbano || '';
  }
}, { immediate: true });
</script>

<style scoped>
.ativo svg {
    color: var(--roxo);
    background-color: initial;
    margin-left: initial;
}

.fa-file-lines {
  color: var(--branco);
  font-size: 68px;
}

.imagem-imovel img{
  max-width: 250px;
  border-radius: 18px;
  width: 250px;
}
</style>