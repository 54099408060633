import { defineStore } from 'pinia';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useDashboardAdmStore = defineStore('dashboard', {
  state: () => ({
    emFoco: null,
    chamadasPendentes: {
      emFoco: false,
      estado: false
    },
    erro: null,
  }),
  actions: {
    async buscarTudo() {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/dashboard`);
        this.emFoco =  request
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async enviarEmail(estado){
      this.chamadasPendentes.estado = true;
      try{
        const request = await this.requestS.post(`${baseUrl}/report/${estado}`)
        this.chamadasPendentes.estado = false;
        return request
      } catch(erro){
        this.erro = erro
      }
    }
  },
});
