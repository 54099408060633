<template>
  <div class="card w-100">
    <font-awesome-icon :icon="props.icone" />
    <h2 class="py-2">{{ props.contagem }}</h2>
    <p>{{ props.descricao }}</p>
  </div>
</template>

<script setup>
const props = defineProps({
  icone: {
    type: Array,
    default: () => [],
    required: true,
  },
  contagem: {
    type: Number,
    default: 0,
    required: true,
  },
  descricao: {
    type: String,
    required: true,
  }
})
</script>

<style scoped>
.card{
  height: auto;
}
.card svg{
  color: var(--roxo);
  background: var(--cinzaClaro);
  width: 30px;
  height: 33px;
  padding: 10px;
  border-radius: 7px;
}

.card h2{
  color: var(--ciano);
  font-size: 32px;
}

.card p{
  font-weight: 500;
}
</style>