<!-- eslint-disable vue/no-v-html -->
<template>
  <template v-if="!resposta">
    <div class="d-flex">
      <font-awesome-icon
        icon="spinner" 
        class="spinner"
      />
      <h3>Carregando...</h3>
    </div>
  </template>
  <template v-else>
    <div>
      <div class="d-flex justify-content-start gap-2 mb-2">
        <a 
          v-if="resposta?.urlExcel"
          :href="resposta?.urlExcel" 
          class="btnBranco" 
          target="_blank"
        >
          <font-awesome-icon icon="file-pdf" />
          Gerar excel da pesquisa
        </a>
      </div>
      <div class="card-branco">
        <router-link
          to="/pesquisas"
          class="btnVoltar"
        >
          <font-awesome-icon
            icon="circle-left"
          />
        </router-link>
        <h1 class="mb-3">
          Visualizar resultados
        </h1>

        <div>
          <h3 class="mb-3">
            {{ resposta?.pesquisa?.title }}
          </h3>
          <p 
            v-html="resposta?.pesquisa?.description"
          />
        </div>
        
        <div>
          <strong class="mt-2">
            Pergunta: {{ resposta?.perguntas?.[0].name }}
          </strong>
        </div>
        <div 
          v-for="(item, index) in resposta?.perguntas?.[0]?.respostas || []" 
          :key="index"
        >
          <div class="bordaCiano mt-3 d-flex gap-3 py-3 px-3 text-start">
            <div class="name">
              <strong>Nome</strong>
              <p>{{ item.user.name }}</p>
            </div>
            <div class="name">
              <strong>Email</strong>
              <p>{{ item.user.email }}</p>
            </div>
            <div>
              <strong>Resposta</strong>
              <p>{{ item.resposta }}</p>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </template>
</template>

<script setup>
import { useAlertStore } from '@/store/alert.store';
import { usePesquisaStore } from '@/store/pesquisas.store';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const alertStore = useAlertStore();

const PesquisaStore = usePesquisaStore()
const { resposta } = storeToRefs(PesquisaStore);

const mostrarModal = ref(false)
const mostrarModalPdf = ref(false)
const mostrarModalFinalizado = ref(false)
const idSelecionado = ref(null);

const props = defineProps({
  pesquisaId: {
    type: Number,
    default: 0,
  },
});


function abrirModalPdf() {
  mostrarModalPdf.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalPdf() {
  mostrarModalPdf.value = false;
  document.body.style.overflow = 'auto';
}


async function iniciar() {
  if(props.pesquisaId){
    await PesquisaStore.buscarRespostas(props.pesquisaId)
  }
}
iniciar()
</script>

<style scoped>
.name {
    min-width: 250px;
}

.name p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.ativo svg {
    background: initial; 
    color: var(--roxo); 
}

</style>