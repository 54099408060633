<template>
  <div class="tabela">
    <table class="w-100 mb-3">
      <col>
      <col>
      <col>
      <thead>
        <tr>
          <th>Método de Pagamento</th>
          <th>Data de Criação</th>
          <th>Valor</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="item in pagamentos"
          :key="item.id"
        >
          <td>{{ item.metodo }}</td>
          <td>{{ dateToField(item.created_at) }}</td>
          <td>{{ dinheiro(item.PAYMENTINFO_0_AMT) }}</td>
        </tr>
        <tr v-if="chamadasPendentes">
          <td colspan="3">
            <font-awesome-icon
              icon="spinner" 
              class="spinner mt-3"
            />
            Carregando
          </td>
        </tr>
        <tr v-else-if="!pagamentos.length">
          <td colspan="3">
            Nenhum resultado encontrado.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import dateToField from '@/helpers/dateToFiel';
import dinheiro from '@/helpers/dinheiro';
import { useAuthStore } from '@/store/auth.store';
import { useClientesStore } from '@/store/clientes.store';
import { storeToRefs } from 'pinia';

const authStore = useAuthStore();
const { user } = storeToRefs(authStore)

const meusClientesStore = useClientesStore()
const { pagamentos } = storeToRefs(meusClientesStore);

async function iniciar(){
  if ( user.value ){
    await meusClientesStore.buscarPagamentos(user.value.id)
  }
}
iniciar()
</script>