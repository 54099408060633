<template>
  <div class="card-branco mt-4">
    <router-link
      to="/login"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <img 
      src="@/assets/imagens/avalionLogo.jpg"
      alt="Logo avalion"
      class="avalionLogo mb-4"
    >
    <div class="mb-4">
      <h2 class="mb-3">
        Bem-vindo ao Sistema de Avaliação Imobiliária AVALION!
      </h2>
      <p>
        Nós do AVALION sabemos que você se importa com a forma como 
        os seus dados pessoais são utilizados e compartilhados. Antes de 
        você iniciar a sua experiência em nosso sistema de avaliação imobiliária, 
        gostaríamos que você soubesse que levamos a sua privacidade a sério. De todo modo, 
        para sua maior segurança e entendimento sobre o funcionamento da nossa plataforma, 
        pedimos para que você disponha de alguns poucos minutos para ler a nossa 
        política de privacidade. Lembre-se também que, ao aceitar os nossos termos de uso e 
        concordar com a nossa política de privacidade, você estará reconhecendo expressamente 
        que aceita as nossas práticas.
      </p>
    </div>

    <div class="mb-4">
      <h2 class="mb-3">
        Quem Somos
      </h2>
      <p class="mb-2">
        O AVALION é um dos serviços prestados pela QUADRA-B ADMINISTRAÇÃO E INCORPORAÇÕES EIRELI, 
        empresa brasileira, sediada no Brasil (República Federativa do Brasil), inscrita no CNPJ 
        sob n. 20.998.722/0001-94, com sede na Rua Sete de Abril, 1.175, Alto da Glória, Curitiba – 
        Paraná, CEP 80.040-120, sendo este o seu controlador.
      </p>
      <p class="mb-2">
        Compete ao controlador toda e qualquer decisão referente ao tratamento dos dados pessoais dos 
        usuários e de terceiros, podendo este ser contatado no telefone (41) 9.9508.3125 ou, ainda, pelo 
        endereço eletrônico: contato@avalion.com.br. Todavia, a plataforma, o sistema, os serviços, a 
        coleta e o descarte dos dados dos usuários são realizados pelo operador do AVALION, a saber, 
        VIVIANE M. B. J. DE ANDRADE (HQS Soluções em TI), pessoa jurídica de direito privado, inscrita no 
        CNPJ n. 22.054.628/0001-02, sediada na Rua Nilo Peçanha 1.354, conjunto 1, Bom Retiro, Curitiba – 
        Paraná, CEP 80.520-176.
      </p>
      <p class="mb-2">
        O operador é constituído, contratado e segue as ordens do controlador, sendo que o primeiro 
        (operador) pode ser contatado através de seus canais de comunicação, seja através do telefone 
        (41) 3095.9091, ou ainda pelo endereço eletrônico: operador@avalion.com.br.
      </p>
      <p class="mb-2">
        O AVALION é uma plataforma de avaliação imobiliária, por onde o usuário pode criar, 
        de forma rápida, fácil, prática e personalizada, PTAMs , bem como ter acesso às opiniões 
        do mercado de imóveis e às análises mercadológicas.
      </p>
    </div>

    <div class="mb-4">
      <h2 class="mb-3">
        Nossos Princípios
      </h2>
      <p>
        A pessoa jurídica detentora da plataforma AVALION visa a proteção dos dados dos seus usuários, 
        atuando com extrema transparência, sempre visando respeitar à privacidade; à autodeterminação 
        informativa; à liberdade de expressão, de informação, de comunicação e de opinião; à inviolabilidade 
        da intimidade, da honra e da imagem; ao desenvolvimento econômico e tecnológico, bem como à inovação e, 
        sobretudo; aos direitos humanos, ao livre desenvolvimento da personalidade, 
        à dignidade e ao exercício da cidadania pelas pessoas naturais.
      </p>
    </div>

    <div class="mb-4">
      <h2 class="mb-3">
        O que fazemos e como fazemos
      </h2>
      <p class="mb-2">
        ara que o usuário tenha acesso à plataforma AVALION, deverá primeiramente ler o termo de acordo 
        e concordar com as políticas de privacidade, práticas e modo de operação do sistema. Havendo 
        expressa concordância, o usuário deverá efetuar seu cadastro, informando, na oportunidade, o 
        seu nome completo ou razão social da sua empresa, CPF ou CNPJ, endereço de e-mail, 
        data de nascimento e telefone celular.
      </p>
      <p class="mb-2">
        Tais dados poderão ser utilizados para melhor identificá-lo e contatá-lo, seja para que 
        possamos melhorar a sua experiência e oportunidades junto a plataforma AVALION, seja para 
        identificar padrões de uso do sistema. Assim, ao nos inserir os dados em nossos sistemas, a 
        AVALION poderá encaminhar ao usuário notificações, ofertas, cupons e materiais promocionais,
        seja via e-mail, seja via telefone celular ou WhatsApp.
      </p>
      <p class="mb-2">
        Sempre que que houver interação com os nossos serviços – seja por meio do nosso site, 
        seja via um aplicativo móvel – automaticamente receberemos e registraremos os dados dos 
        usuários em nosso servidor a partir de um navegador ou dispositivo, os quais podem incluir o 
        endereço IP (internet protocol address – endereço de protocolo da internet), dados de 
        geolocalização, identificação do dispositivo, dados de cookies, o tipo de navegador e 
        o dispositivo utilizado, bem como a página, o recurso solicitado e o tempo de acesso.
      </p>
      <p class="mb-2">
        Para aproveitar os benefícios dos serviços prestados através do AVALION – em especial para 
        criar, de forma personalizada, PTAMs – o usuário poderá fornecer alguns dados que irão otimizar a 
        sua experiência com a plataforma – dentre eles, informações pessoais sensíveis, de natureza fiscal, 
        própria ou de terceiros, exclusivamente em relação ao imposto municipal incidente sobre a propriedade 
        imóvel urbana – IPTU. Ao se utilizar, de forma voluntária, de informações de natureza tributária 
        no PTAM e/ou na avaliação mercadológica no sistema AVALION, o usuário deverá estar ciente 
        de que estará reproduzindo dados sensíveis protegidos por sigilo fiscal.
      </p>
      <p class="mb-2">
        Os dados pessoais ficarão disponíveis em nosso sistema pelo prazo máximo de 60 (sessenta) meses, 
        a contar do término do plano aderido pelo usuário. Ultrapassado este período, 
        os dados serão descartados de todos os seus arquivos.
      </p>
      <p class="mb-2">
        Todos os dados coletados pelo AVALION no ato de cadastramento se pautarão no mínimo necessário 
        à realização de suas finalidades e terão por finalidade a identificação de seus usuários – 
        seja para sua proteção, seja para proteção de terceiros – bem como para viabilizar meios de 
        acusar os pagamentos realizados por meio da plataforma 
        <a href="https://www.paypal.com/br/home">PAYPAL®BRASIL </a> e <a href="https://www.cobrefacil.com.br/">COBRE FÁCIL.</a>
      </p>
    </div>

    <div class="mb-4">
      <h2 class="mb-3">
        O que fazemos
      </h2>
      <p class="mb-2">
        O tratamento dos dados realizados através da plataforma AVALION se destinam única e 
        exclusivamente à coleta, recepção, classificação, utilização, acesso, processamento, 
        armazenamento, arquivamento, eliminação, avaliação e controle de informação. O AVALION 
        não trata dados de terceiros – em especial aqueles que são informados pelo usuário para 
        realização de PTAMs de imóveis e de avaliação mercadológica.
      </p>
      <p class="mb-2">
        Igualmente, os PTAMs e mercadológicos gerados pelo usuário não ficam salvos em nosso banco 
        de dados. Desta forma, ao reproduzir dados pessoais sensíveis nestes documentos – tal como 
        informações fiscais – nenhuma versão ficará salva e/ou disponível no AVALION, nem mesmo para 
        fins de futura recuperação do documento.
      </p>
      <p class="mb-2">
        Em relação a seus dados bancário e financeiros – tais como (mas sem se limitar a) número
        de cartão de crédito ou de débito, bandeira, vencimento e código de segurança – tais informações 
        são fornecidas pelo usuário na plataforma de pagamento <a href="https://www.paypal.com/br/home">PAYPAL®BRASIL</a>. Desta forma, o 
        controlador do AVALION não tem acesso a qualquer um destes dados, exceto quanto à forma de 
        pagamento optada pelo usuário no ato de compra de um dos planos ofertados.
      </p>
      <p class="mb-2">
        Por fim, informamos que não aceitamos dados fornecidos por menores de 18 anos, ou mesmo fazendo 
        qualquer tipo de coleta e/ou tratamento com tais informações. Se você tiver 
        conhecimento de que algum menor forneceu seus dados, por favor, entre em contato 
        com o nosso encarregado para que possamos tomar as medidas pertinentes.
      </p>
      <p class="mb-2">
        Não transferimos, vendemos e/ou compartilhamos qualquer tipo de dado dos usuários com terceiros, 
        exceto para sistemas de cobrança de parceiros (como o <a href="https://www.cobrefacil.com.br/">COBRE FÁCIL.</a> ), especificamente para os 
        casos de opção de pagamento via boleto bancário. Igualmente, não remetemos informações a banco 
        de dados e/ou servidor localizado no exterior. Todos os dados são coletados e mantidos em
        servidores localizados em território nacional (República Federativa do Brasil).
      </p>
      <p class="mb-2">
        Por fim, informamos que todos os parâmetros, informações dados imobiliários utilizados pelo 
        sistema AVALION são de domínio público, não se tratando, assim, de dados pessoais de 
        qualquer natureza – sobretudo dados sensíveis.
      </p>
    </div>

    <div class="mb-4">
      <h2 class="mb-3">
        Sobre os cookies
      </h2>
      <p class="mb-2">
        Cookies são identificadores que a AVALION utiliza para transferir ao navegador ou dispositivo 
        do usuário, especialmente para que possamos reconhecê-lo, bem como para informar como e 
        quando as páginas e recursos são visitados em nossos serviços.
      </p>
      <p class="mb-2">
        Por meio de cookies, a AVALION pode coletar dados sobre sua atividade online depois que o 
        usuário interromper a utilização dos nossos serviços. Assim como outros dados de uso que 
        coletamos, esses dados nos permitem melhorar os serviços e personalizar a experiência de uso. 
        Atualmente, nossos serviços não são compatíveis com solicitações de "não rastreamento", o que 
        significa que poderemos coletar dados sobre sua atividade online enquanto 
        estiver operando a plataforma.
      </p>
      <p class="mb-2">
        Especificamente, usamos os cookies de acordo com as seguintes finalidades:
        <ul>
          <li>
            Autenticação de status de logon: usamos os cookies para identificá-lo ao acessar o 
            nosso site ou nosso aplicativo; 
          </li>
          <li>
            Autenticação de status de logon: usamos os cookies para identificá-lo ao acessar o 
            nosso site ou nosso aplicativo;
          </li>
          <li>
            Google Analytics: usamos os serviços e as ferramentas do Google para analisar o 
            comportamento e os padrões de utilização do usuário em nossa plataforma (site e aplicativo). 
            O Google coleta dados sobre a utilização do site e do aplicativo através de cookies 
            e os utiliza para criar relatórios sobre o a nossa plataforma.
          </li>
        </ul>
      </p>
      <p class="mb-2">
        A política de privacidade do Google está disponível em: 
        <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a> 
        e ao usar o link <a href="https://www.google.com/settings/ads">https://www.google.com/settings/ads</a> 
        o usuário pode configurar ou desvincular alguns desses métodos de coleta de dados.
      </p>
      <p class="mb-2">
        Por fim, esclarecemos que é possível alterar suas preferências no navegador ou dispositivo, 
        a fim de impedir ou limitar a aceitação de cookies no dispositivo. Entretanto, 
        essa ação pode impedi-lo de desfrutar de alguns dos nossos recursos.
      </p>
    </div>

    <div class="mb-4">
      <h2 class="mb-3">
        Direitos do usuário
      </h2>
      <p>
        O usuário, enquanto único e exclusivo titular de seus dados pessoais, tem o direito a 
        obter do controlador do AVALION, por meio de seu 
        <a target="_blank" href="mailto:suporte@avalion.com.br">encarregado</a>, e a qualquer momento, e 
        mediante requisição: a confirmação da existência de qualquer tipo de tratamento; acesso 
        aos dados relativos ao usuário; a correção de dados incompletos, inexatos ou desatualizados; 
        anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em 
        desconformidade com o disposto nesta política de privacidade, com o termo de acordo, ou 
        ainda com a Lei n. 13.709/2018 (Lei Geral de Proteção de Dados); em caso de eventual 
        portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição 
        expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos 
        comercial e industrial; eliminação dos dados pessoais tratados com o consentimento do 
        titular, exceto nas hipóteses de cumprimento de obrigação legal ou regulatória pelo 
        controlador; informação das entidades públicas e privadas com as quais o controlador 
        realizou uso compartilhado de dados; receber informação clara sobre a possibilidade de 
        não fornecer consentimento e sobre as consequências da negativa; revogação de qualquer 
        consentimento prestado.
      </p>
    </div>

    <div class="mb-4">
      <h2 class="mb-3">
        Meus dados estão seguros?
      </h2>
      <p>
        A fim de resguardar a sua privacidade e segurança, sua conta é protegida por um usuário e 
        senha – as quais são pessoais e intransferíveis. Você deve evitar o acesso não autorizado 
        à sua conta e aos seus dados pessoais por meio da seleção e proteção adequada da sua senha. 
        Igualmente, o usuário deve limitar o acesso ao seu computador ou ao seu dispositivo móvel, 
        bem como ao navegador utilizado, s empre encerrando a sua sessão imediatamente após o 
        término da utilização da plataforma.
      </p>
      <p>
        Os agentes de tratamento dos seus dados aplicam e aplicarão os melhores esforços a fim de 
        proteger a sua privacidade. Esclarecemos que o operador utiliza de sistemas de 
        firewall e de criptografia de informações. Isto significa que os seus dados – 
        como, por exemplo, a sua senha – são criptografados e não podem ser acessados por 
        terceiros – nem mesmo pela própria empresa que presta os serviços de hospedagem
      </p>
    </div>

    <div class="mb-4">
      <h2 class="mb-3">
        Em qual servidor os meus dados estarão armazenados?
      </h2>
      <p>
        Seus dados estarão armazenados em um servidor localizado em território nacional, nas 
        instalações de serviços da VIVIANE M. B. J. DE ANDRADE (HQS Soluções em TI), pessoa 
        jurídica de direito privado, inscrita no CNPJ n. 22.054.628/0001-02, sediada na Rua Nilo 
        Peçanha 1.354, conjunto 1, Bom Retiro, Curitiba – Paraná, CEP 80.520-176.
      </p>
    </div>

    <div class="mb-4">
      <h2 class="mb-3">
        Métodos de pagamento
      </h2>
      <p class="mb-2">
        Para usufruir dos nossos serviços, o Avalion disponibiliza diversos planos, os quais ~
        demandam uma fidelização miníma por parte do usuário, pelo Paypal Brasil a fidelização é 
        de 12(doze) meses e pela plataforma de boletos <a href="https://www.cobrefacil.com.br/">COBRE FÁCIL.</a> a fidelização é de 6(seis) meses.
      </p>
      <p class="mb-2">
        O Avalion se reserva no direito de alterar, modificar ou revogar unilateralmente, 
        e sem aviso prévio, todos os seus planos e pacotes, bem como os meios e plataformas de cobrança.
      </p>
      <p class="mb-2">
        O usuário poderá efetuar o pagamento do plano contratado via cartão de crédito 
        (através da plataforma <a href="https://www.paypal.com/br/home">PAYPAL®BRASIL</a>) ou boleto bancário 
        (através da plataforma <a href="https://www.cobrefacil.com.br/">COBRE FÁCIL.</a>). 
        Nesta última hipótese, a plataforma <a href="https://www.cobrefacil.com.br/">COBRE FÁCIL.</a> cobrará taxas adicionais para a emissão deste 
        tipo de documento (boleto).
      </p>
      <p class="mb-2">
        Especificamente quanto ao pagamento via cartão de crédito, informamos que, por utilizarmos 
        os serviços da plataforma <a href="https://www.paypal.com/br/home">PAYPAL®BRASIL</a>, não acessamos, armazenamos, processamos ou 
        realizamos qualquer tipo de tratamento dos seus dados financeiros e bancários, uma vez 
        que todas essas informações são coletadas pelo sistema de cobrança de terceiros. Assim, 
        caso o usuário tenha alguma dúvida ou queira obter maiores informações sobre a forma como 
        são processados e tratados os seus dados bancários, sugerimos a leitura dos termos de uso e da 
        política de privacidade da <a href="https://www.paypal.com/br/home">PAYPAL®BRASIL</a>.
      </p>
      <p class="mb-2">
        Já para a realização de pagamento dos nossos serviços via boleto bancário, informamos 
        que tratamos apenas os dados fornecidos pelo próprio usuário que se mostrem suficientes 
        e necessários à emissão do título – tais como nome completo ou razão social, endereço 
        (residencial ou da sede da pessoa jurídica) e CPF/CNPJ. Destacamos, ainda, que recai 
        exclusivamente sobre o usuário a responsabilidade acerca da veracidade dos dados preenchidos 
        em nosso sistema.
      </p>
      <p class="mb-2">
        Em razão de sua política de privacidade, a qualquer tempo o <a href="https://www.cobrefacil.com.br/">COBRE FÁCIL.</a> se reserva no direito 
        de contatar o usuário para validar a veracidade das informações prestadas pelo Avalion em seu 
        sistema de cobrança, bem como o reconhecimento da transação/emissão do boleto bancário ou de 
        outro meio de pagamento solicitada pelo usuário na plataforma Avalion. Em caso de sinalização 
        negativa de legitimidade e veracidade das informações, o <a href="https://www.cobrefacil.com.br/">COBRE FÁCIL.</a> poderá efetuar o cancelamento 
        do título.
      </p>
      <p class="mb-2">
        Caso o usuário tenha alguma dúvida ou queira obter maiores informações sobre a forma 
        como são processados e tratados os seus dados pelo <a href="https://www.cobrefacil.com.br/">COBRE FÁCIL.</a>, sugerimos a leitura dos 
        termos de uso e da política de privacidade desta plataforma parceira.
      </p>
      <p class="mb-2">
        Em observância aos nossos princípios e à nossa política de privacidade, utilizamos os 
        serviços de plataforma
      </p>
    </div>

    <div class="mb-4">
      <h2 class="mb-3">
        Suporte técnico
      </h2>
      <p>
        Caso o sistema apresente inconsistências, falhas, lentidão ou qualquer outro tipo de problema que 
        prejudique a regular utilização do AVALION, o usuário poderá contatar o operador para esclarecimentos, 
        correções, auxílios e reparos
      </p>
      <p>
        Por fim, caso tenha interesse em entender melhor a nossa forma de trabalho, a nossa forma de 
        coleta e armazenamento de dados, forma de descarte, bem como revogar qualquer informação que 
        outrora tenha nos sido fornecida, o usuário poderá no contatar através dos nossos canais de comunicação.
      </p>
      <p>
        Ficamos desde já inteiramente à sua disposição e desejamos uma ótima experiência 
        com a nossa plataforma, Atenciosamente;
      </p>
      <strong class="mt-3">A equipe AVALION.</strong>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.card-branco {
  margin: auto;
  max-width: 1200px;
}

.avalionLogo {
  max-width: 280px;
  display: block;
  margin: auto;
}

.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}
</style>