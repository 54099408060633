export default {
  "yes": {
    nome: 'Sim',
    valor: 'yes',
  },
  "no": {
    nome: 'Não',
    valor: 'no',
  },
};
