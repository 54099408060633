import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const usePesquisaInteligenteStore = defineStore('PesquisaInteligente', {
  state: () => ({
    lista: [],
    emFoco: null,
    filePath: "",
    pesquisaId: null,
    paginacao: {
      links: [],
      current_page: null,
      last_page: null,
      per_page: 20,
      total: null,
    },
    chamadasPendentes: {
      lista: false,
      emFoco: false,
      filePath: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/smart-search`, {
          ...params,
          page: params.page || this.paginacao.current_page || 1,
          per_page: this.paginacao.per_page,
        });
        this.lista = request.smartSearches.data

        this.paginacao.current_page = request.smartSearches.current_page;
        this.paginacao.last_page = request.smartSearches.last_page;
        this.paginacao.per_page = request.smartSearches.per_page;
        this.paginacao.total = request.smartSearches.total;
        this.paginacao.links = request.smartSearches.links;
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },

    async mudarPagina(page) {
      this.chamadasPendentes.lista = true;
      this.erro = null;
      try {
        this.paginacao.current_page = page;
        this.buscarTudo();
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },
    
    async buscarItem(id = 0, params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/smart-search/${id}`);
        this.emFoco = resposta.smartSearch
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async excluirItem(id) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        await this.requestS.delete(`${baseUrl}/smart-search/${id}`);
        this.chamadasPendentes.lista = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.lista = false;
        return false;
      }
    },

    async enviarStatus(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.put(`${baseUrl}/smart-search-set-finished/${id}`, params);
        const alertStore = useAlertStore();
        alertStore.success('Status Atualizado!');
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async gerarPdf(id, layout){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const resposta = await this.requestS.post(`${baseUrl}/smart-search-print/${id}`, layout);
        this.filePath = resposta.filePath
        return this.filePath
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async enviarOpiniaoEmail(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const resposta = await this.requestS.post(`${baseUrl}/smart-search-send/${id}`, params);
        const alertStore = useAlertStore();
        alertStore.success('Arquivo colocado para fila de envio');
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async salvarItem(params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const request = await this.requestS.post(`${baseUrl}/smart-search`, params)
        this.chamadasPendentes.emFoco = false;
        this.pesquisaId = request.smartSearch.id
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },
  },
});
