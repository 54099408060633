import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const usePesquisaStore = defineStore('pesquisa', {
  state: () => ({
    lista: [],
    pesquisaId: null,
    listaPergunta: [],
    pesquisa: {},
    resposta: {},
    pesquisaAtiva: {},
    paginacao: {
      links: [],
      current_page: null,
      last_page: null,
      per_page: 20,
      total: null,
    },
    chamadasPendentes: {
      lista: false,
      pesquisa: false,
      resposta: false,
      pesquisaAtiva: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/pesquisas`, {
          ...params,
          page: params.page || this.paginacao.current_page || 1,
          per_page: this.paginacao.per_page,
        });
        this.lista = request.data

        this.paginacao.current_page = request.current_page;
        this.paginacao.last_page = request.last_page;
        this.paginacao.per_page = request.per_page;
        this.paginacao.total = request.total;
        this.paginacao.links = request.links;
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },

    async mudarPagina(page) {
      this.chamadasPendentes.lista = true;
      this.erro = null;
      try {
        this.paginacao.current_page = page;
        this.buscarTudo();
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },
    
    async buscarItem(id = 0) {
      this.chamadasPendentes.pesquisa = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/pesquisas/${id}`);
        this.pesquisa = resposta.pesquisa
        this.listaPergunta = resposta.perguntas
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.pesquisa = false;
    },

    async buscarRespostas(id = 0) {
      this.chamadasPendentes.resposta = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/pesquisa-resposta/${id}`);
        this.resposta = resposta
        console.log(this.resposta)
        this.chamadasPendentes.resposta = false;
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.resposta = false;
    },

    async buscarPesquisaAtiva() {
      this.chamadasPendentes.pesquisaAtiva = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/pesquisa-ativa`);
        this.pesquisaAtiva = resposta
        this.chamadasPendentes.pesquisaAtiva = false;
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.pesquisaAtiva = false;
    },

    async salvarPergunta(params = {}, id = 0) {
      this.chamadasPendentes.pesquisa = true;
      this.erro = null;
      try {
        if (id) {
          await this.requestS.put(`${baseUrl}/perguntas/${id}`, params);
        } else {
          await this.requestS.post(`${baseUrl}/perguntas`, params);
        }

        this.chamadasPendentes.pesquisa = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.pesquisa = false;
        return false;
      }
    }, 

    async salvarItem(params = {}, id = 0) {
      this.chamadasPendentes.pesquisa = true;
      this.erro = null;
      try {
        let request
        if (id) {
          request = await this.requestS.put(`${baseUrl}/pesquisas/${id}`, params);
        } else {
          request = await this.requestS.post(`${baseUrl}/pesquisas`, params);
          this.pesquisaId = request.id
        }
        this.chamadasPendentes.pesquisa = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.pesquisa = false;
        return false;
      }
    },

    async salvarResposta(params = {}) {
      this.erro = null;
      try {
        await this.requestS.post(`${baseUrl}/pesquisa-resposta`, 
          params
        ); 
        return true;
      } catch (erro) {
        this.erro = erro;
        return false;
      }
    },
  },
});
