import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useAnaliseMercadoStore = defineStore('AnaliseMercado', {
  state: () => ({
    lista: [],
    listaCalculo: [],
    emFoco: null,
    filePath: "",
    analiseId: null,
    paginacao: {
      links: [],
      current_page: null,
      last_page: null,
      per_page: 20,
      total: null,
    },
    chamadasPendentes: {
      lista: false,
      listaCalculo: false,
      emFoco: false,
      filePath: false,
      analiseId: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/market-analysis`, {
          ...params,
          page: params.page || this.paginacao.current_page || 1,
          per_page: this.paginacao.per_page,
        });
        this.lista = request.marketAnalysis.data

        this.paginacao.current_page = request.marketAnalysis.current_page;
        this.paginacao.last_page = request.marketAnalysis.last_page;
        this.paginacao.per_page = request.marketAnalysis.per_page;
        this.paginacao.total = request.marketAnalysis.total;
        this.paginacao.links = request.marketAnalysis.links;
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },

    async mudarPagina(page) {
      this.chamadasPendentes.lista = true;
      this.erro = null;
      try {
        this.paginacao.current_page = page;
        this.buscarTudo();
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },
    
    async buscarItem(id) {
      this.chamadasPendentes.emFoco = true;
      this.chamadasPendentes.listaCalculo = true;
      this.erro = null;

      try {
        const request = await this.requestS.get(`${baseUrl}/market-analysis/${id}`);
        this.emFoco = request.marketAnalysis;
        this.analiseId = request.marketAnalysis.id;
        this.listaCalculo = request.marketAnalysis.samples
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.listaCalculo = false;
      this.chamadasPendentes.emFoco = false;
    },

    async excluirItem(id) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        await this.requestS.delete(`${baseUrl}/market-analysis/${id}`);
        this.chamadasPendentes.lista = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.lista = false;
        return false;
      }
    },

    async excluirAmostraRecalcular(id) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        await this.requestS.delete(`${baseUrl}/market-analysis-delete-sample/${id}`);
        this.chamadasPendentes.lista = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.lista = false;
        return false;
      }
    },

    async gerarPdf(id, layout){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const response = await this.requestS.post(`${baseUrl}/market-analysis-print/${id}`, layout);
        this.filePath = response.filePath
        return this.filePath
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async enviarStatus(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.put(`${baseUrl}/market-analysis-set-finished/${id}`, params);
        const alertStore = useAlertStore();
        alertStore.success('Status Atualizado!');
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },
   
    async anexarFoto(id, params){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.upload(`${baseUrl}/market-analysis-files/${id}`, params);
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async atualizarFoto(id, params){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.put(`${baseUrl}/market-update-file/${id}`, params);
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async enviarAnaliseEmail(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const request = await this.requestS.post(`${baseUrl}/market-analysis-send/${id}`, params);
        const alertStore = useAlertStore();
        alertStore.success('Arquivo colocado para fila de envio');
        return request
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async enviarAnaliseDetalhe(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.put(`${baseUrl}/market-analysis/${id}`, params);
        return true
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async salvarItem(params = {}) {
      this.chamadasPendentes.emFoco = true;
      this.chamadasPendentes.listaCalculo = true;
      this.erro = null;
      try {
        const request =  await this.requestS.post(`${baseUrl}/market-analysis`, params)
        this.chamadasPendentes.emFoco = false;
        this.chamadasPendentes.listaCalculo = false;
        this.analiseId = request.marketAnalysis.id;
        this.listaCalculo = request.marketAnalysis.samples
        this.emFoco = request.marketAnalysis
        return request
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },
  },
});
