<template>
  <div>
    <div class="pb-4">
      <div class="d-flex justify-content-between align-items-center pb-2">
        <h1 class="pb-2">Usuários</h1>
      </div>
      <div class="tabela">
        <table class="w-100">
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Último login</th>
              <th>Data de criação</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <template v-for="item in meusFilhos" :key="item.id">
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.last_login? dateToField(item.last_login) : '' }}</td>
                <td>{{ dateToField(item.created_at) }}</td>
                <td class="tdBtnAcao">
                  <router-link
                    v-if="item.parent_id"
                    :to="{ 
                      name: 'Usuario Editar',
                      params: { usuarioId: item.id } 
                    }"
                    class="icon"
                  >
                    <font-awesome-icon icon="pen-to-square" />
                  </router-link>
                </td>
              </tr>
            </template>
            <tr v-if="chamadasPendentes.lista">
              <td colspan="6">
                <font-awesome-icon icon="spinner" class="spinner mt-3" />
                Carregando
              </td>
            </tr>
            <tr v-else-if="erro">
              <td colspan="6">
                Erro: {{ erro }}
              </td>
            </tr>
            <tr v-else-if="!meusFilhos.length">
              <td colspan="6">
                Nenhum resultado encontrado.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import dateToField from '@/helpers/dateToFiel';
import { useMeusUsuariosStore } from '@/store/meusUsuarios.store';
import { storeToRefs } from 'pinia';

const meusUsuariosStore = useMeusUsuariosStore()
const { meusFilhos, chamadasPendentes, erro } = storeToRefs(meusUsuariosStore);

const props = defineProps({
  clienteId: {
    type: Number,
    default: 0,
  },
});

async function iniciar(){
  if ( props.clienteId){
    await meusUsuariosStore.buscarMeusFilhos(props.clienteId)
  }
}
iniciar()
</script>

<style scoped>
.btnBranco{
  color: var(--roxo)
}
</style>