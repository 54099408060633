import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';
// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const usePtamsStore = defineStore('ptams', {
  state: () => ({
    lista: [],
    listaAmostra: [],
    listaCalculo: [],
    evolucaoCalculo: null,
    determinacaoCalculo: null,
    calculoImovel: null,
    infoImovel: null,
    infoProprietario: null,
    imovelId: null,
    proprietarioId: null,
    laudoIdGet: null,
    laudo: null,
    filePath: "",
    paginacao: {
      links: [],
      current_page: null,
      last_page: null,
      per_page: 20,
      total: null,
    },
    chamadasPendentes: {
      lista: false,
      infoImovel: false,
      infoProprietario: false,
      listaCalculo: false,
      proprietarioId: false,
      filePath: false,
      laudo: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/laudos`, {
          ...params,
          page: params.page || this.paginacao.current_page || 1,
          per_page: this.paginacao.per_page,
        });

        this.lista =  request.avaliacoes.data

        this.paginacao.current_page = request.avaliacoes.current_page;
        this.paginacao.last_page = request.avaliacoes.last_page;
        this.paginacao.per_page = request.avaliacoes.per_page;
        this.paginacao.total = request.avaliacoes.total;
        this.paginacao.links = request.avaliacoes.links;

      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },

    async buscarTudoRascunho(params = {}) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/properties`, {
          ...params,
          page: params.page || this.paginacao.current_page || 1,
          per_page: this.paginacao.per_page,
        });

        this.lista =  request.properties.data

        this.paginacao.current_page = request.properties.current_page;
        this.paginacao.last_page = request.properties.last_page;
        this.paginacao.per_page = request.properties.per_page;
        this.paginacao.total = request.properties.total;
        this.paginacao.links = request.properties.links;

      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },
    
    async buscarInfoImovel(id = 0) {
      this.chamadasPendentes.infoImovel = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/propertie/${id}`);
        this.infoImovel = resposta.propertie
        this.listaAmostra = resposta.propertie.amostras
        this.proprietarioId = resposta.propertie.proprietario_id
        if(this.proprietarioId !== null){
          await this.buscarProprietario(this.proprietarioId)
        }
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.infoImovel = false;
    },

    async excluirAmostraRecalcular(id) {
      this.chamadasPendentes.listaCalculo = true;
      this.erro = null;

      try {
        await this.requestS.delete(`${baseUrl}/samples-ptam/${id}`);
        this.chamadasPendentes.listaCalculo = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.listaCalculo = false;
        return false;
      }
    },

    async buscarProprietario(id = 0) {
      this.chamadasPendentes.infoProprietario = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/owner/${id}`);
        this.infoProprietario = resposta.owner
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.infoProprietario = false;
    },

    async buscarLaudo(id = 0) {
      this.chamadasPendentes.listaCalculo = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/laudo-create?imovel_id=${id}`);
        this.listaCalculo = resposta.imovel.amostras
        this.evolucaoCalculo = resposta.evolucao
        this.determinacaoCalculo = resposta.determinacao
        this.calculoImovel = resposta.calculoImovel

      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.listaCalculo = false;
    },

    async buscarLaudoId(id = 0) {
      this.chamadasPendentes.laudo = true;
      this.erro = null;
      try {
        const resposta = await this.requestS.get(`${baseUrl}/laudo/${id}`);
        this.laudo = resposta.laudo
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.laudo = false;
    },

    async atualizarLaudo(id, params = {}) {
      try {
        await this.requestS.put(`${baseUrl}/laudo-update/${id}`, params);
        return true
      } catch (erro) {
        this.erro
      }
    },
    
    async anexarArquivosPtams(id, params){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.upload(`${baseUrl}/properties-upload-files/${id}`, params);
        return true
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async enviarAnaliseAvaliacao(id, params){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.put(`${baseUrl}/laudo-update/${id}`, params);
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async fatorOferta(params){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.post(`${baseUrl}/laudo-aplicar-fator-oferta`, params);
        return true
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async enviarStatus(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.put(`${baseUrl}/laudo-set-finished/${id}`, params);
        const alertStore = useAlertStore();
        alertStore.success('Marcado como finalizado!');
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async mudarPagina(page) {
      this.chamadasPendentes.lista = true;
      this.erro = null;
      try {
        this.paginacao.current_page = page;
        await this.buscarTudo();
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.lista = false;
    },

    async salvarProprietario(params = {}, id = null) {
      this.chamadasPendentes.infoProprietario = true;
      this.erro = null;
      let request
      try {
        if (id) {
          request = await this.requestS.put(`${baseUrl}/owner/${id}`, params);
          this.infoProprietario = request.owner
        } else {
          request = await this.requestS.post(`${baseUrl}/owner`, params);
        }
        return true;
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.infoProprietario = false;
    },

    async excluirItem(id) {
      this.chamadasPendentes.lista = true;
      this.erro = null;

      try {
        await this.requestS.delete(`${baseUrl}/properties/${id}`);
        this.chamadasPendentes.lista = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.lista = false;
        return false;
      }
    },

    async salvarAmostras(params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        await this.requestS.post(`${baseUrl}/samples-ptam`, params);
        return true;
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async salvarCalculoCriarLaudo(params = {}){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const resposta = await this.requestS.post(`${baseUrl}/laudo-create`, params);
        this.laudoIdGet = resposta.laudo.id
        return true
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async salvarItem(params = {}, id = null) {
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      let request;
      try {
        if (id) {
          request = await this.requestS.put(`${baseUrl}/propertie/${id}`, params);
        } else {
          request = await this.requestS.post(`${baseUrl}/propertie`, params);
          this.imovelId = request.propertie.id
        }
        this.chamadasPendentes.emFoco = false;
        return true;
      } catch (erro) {
        this.erro = erro;
        this.chamadasPendentes.emFoco = false;
        return false;
      }
    },

    async enviarPtamsEmail(id, params = {} ){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const request = await this.requestS.post(`${baseUrl}/laudo-send/${id}`, params);
        const alertStore = useAlertStore();
        alertStore.success('Arquivo colocado para fila de envio');
        return request
      } catch (erros) {
        this.erros = erros;
      }
      this.chamadasPendentes.emFoco = false;
    },

    async gerarPdf(id, layout){
      this.chamadasPendentes.emFoco = true;
      this.erro = null;
      try {
        const response = await this.requestS.post(`${baseUrl}/laudo-print/${id}`, layout);
        this.filePath = response.filePath
        return this.filePath
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.emFoco = false;
    },
  },
});
