<template>
  <div class="card-branco w-100">
    <div class="d-flex justify-content-between align-items-center">
      <h1>Pacotes adquiridos</h1>
      <CButton 
        class="d-flex align-items-center ver-mais"
        @click="visible = !visible"
      >
        Ver mais 
        <font-awesome-icon 
          class="ps-2" 
          icon="circle-plus"
        />
      </CButton>
    </div>
    <CCollapse :visible="visible">
      <CCard class="my-3">
        <CCardBody class="p-0">
          <div class="tabela">
            <table class="w-100 mb-3">
              <col>
              <col>
              <col>
              <col>
              <col>
              <col>
              <thead>
                <tr>
                  <th>Método de pagemento</th>
                  <th>Opiniões de Mercado Adquiridas</th>
                  <th>PTAMs Adquiridas</th>
                  <th>Análises de Avaliação Adquiridas</th>
                  <th>Usuários Adquiridas</th>
                  <th>Adesão</th>
                  <th>Expiração </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="item in adquiridos.slice(0, 5)"
                  :key="item.id"
                >
                  <td class="descricao">{{ item.metodo_pagamento }}</td>
                  <td>{{ item.quantidade_opinioes }}</td>
                  <td>{{ item.quantidade_laudos }}</td>
                  <td>{{ item.quantidade_analises }}</td>
                  <td>{{ item.quantidade_usuarios }}</td>
                  <td>{{ item.created_at ? dateToField(item.created_at) : '-' }}</td>
                  <td>{{ item.data_expiracao ? dateToField(item.data_expiracao) : '-' }}</td>
                </tr>
                <tr v-if="!adquiridos.length">
                  <td colspan="3">
                    Nenhum resultado encontrado.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
    </CCollapse>
  </div>
</template>



<script setup>
import dateToField from '@/helpers/dateToFiel';
import dinheiro from '@/helpers/dinheiro';
import { useAuthStore } from '@/store/auth.store';
import { useClientesStore } from '@/store/clientes.store';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const visible = ref(false)

const authStore = useAuthStore();
const { user } = storeToRefs(authStore)

const meusClientesStore = useClientesStore()
const { adquiridos } = storeToRefs(meusClientesStore);

async function iniciar(){
  if ( user.value ){
    await meusClientesStore.buscarPacotesAdiquiridos(user.value.id)
  }
}
iniciar()
</script>

<style scoped>
.endereco{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
}

.ver-mais{
  background-color: transparent;
  border: none;
  text-wrap: nowrap;
  color: var(--roxo);
  font-weight: 500;
}

h1 {
  color: var(--roxo);
}
</style>