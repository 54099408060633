export default {
  "Boleto Bancário": {
    nome: 'Boleto Bancário',
    valor: 'Boleto Bancário',
  },
  "Depósito em Conta": {
    nome: 'Depósito em Conta',
    valor: 'Depósito em Conta',
  },
  "Paypal": {
    nome: 'Paypal',
    valor: 'Paypal',
  },
  "Premiação": {
    nome: 'Premiação',
    valor: 'Premiação',
  },
  "Transferência Bancária": {
    nome: 'Transferência Bancária',
    valor: 'Transferência Bancária',
  },
  "Vindi": {
    nome: 'Vindi',
    valor: 'Vindi',
  },
  "Vindi - Boleto Bancário": {
    nome: 'Vindi - Boleto Bancário',
    valor: 'Vindi - Boleto Bancário',
  },
  "Vindi - Pix": {
    nome: 'Vindi - Pix',
    valor: 'Vindi - Pix',
  },
};
