<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>Clientes</h1>
      <div class="d-flex align-items-center gap-3">
        <strong>Total: {{ paginacao.total }}</strong>
        <router-link
          :to="{ name: 'Cliente criar' }"
          class="btnRoxo"
        >
          Novo cliente
        </router-link>  
      </div>
    </div>

    <div>
      <strong
        style="color: var(--roxo)"
      >
        Filtrar por:
      </strong>
      <form
        class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
        @submit.prevent="atualizarUrl"
      >
        <div class="d-flex gap-3 align-items-center">
          <div class="mw-100">
            <label>Nome</label>
            <input
              id="name"
              v-model.trim="name"
              placeholder="name"
              class="inputtext"
              name="name"
              type="text"
            >
          </div>

          <div class="d-grid">
            <label>Plano</label>
            <select
              id="plano"
              v-model.trim="plano"
              class="inputtext inputtextMenor"
              name="plano"
            >
              <option 
                value=""
              />
              <option
                v-for="item in Object.values(planosRegulares)"
                :key="item.nome"
                :value="item.nome"
              >
                {{ item.nome }}
              </option>
            </select>
          </div>

          <div>
            <label>CPF/CNPJ</label>
            <input
              id="cpf"
              v-model.trim="cpf"
              placeholder="CPF/CNPJ"
              class="inputtext"
              name="cpf"
              type="text"
            >
          </div>

          <div class="mw-100">
            <label>Cidade</label>
            <input
              id="city"
              v-model.trim="city"
              placeholder="Cidade"
              class="inputtext"
              name="city"
              type="text"
            >
          </div>

          <div class="d-flex gap-2 mt-4 align-items-center">
            <input
              v-model="active"
              name="active"
              type="checkbox"
              class="inputcheckbox"
              :value="true"
            >
            <label class="pb-0">Assinatura ativa</label>
          </div>

          <div class="d-flex gap-2 mt-4 align-items-center">
            <input
              v-model="pagantes"
              name="pagantes"
              type="checkbox"
              class="inputcheckbox"
              :value="true"
            >
            <label class="pb-0">Usuários Pagantes</label>
          </div>
        </div>
        <div class="d-flex gap-3">
          <button @click="limparFiltro" type="button" class="btnLabel">
            <font-awesome-icon
              icon="trash"
            />
            Limpar
          </button>
          <button class="btnAmarelo">
            Pesquisar
          </button>
        </div>
      </form>
      <div class="tabela">
        <table class="w-100">
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>CPF/CNPJ</th>
              <th>Cidade</th>
              <th>Telefone</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="item in lista"
              :key="item.id"
            >
              <td class="name">{{ item.name }}</td>
              <td class="email">{{ item.email }}</td>
              <td>{{ item.cpf ? item.cpf : '-' }}</td> 
              <td>{{ item.cidade ? item.cidade : '-' }}</td> 
              <td>{{ item.telefone_celular ? item.telefone_celular : '-' }}</td> 
              <div class="d-flex gap-2">
                <td class="tdBtnAcao">
                  <router-link
                    :to="{ 
                      name: 'Vizualizar cliente', 
                      params: { clienteId: item.id }
                    }"
                    class="icon"
                  >
                    <font-awesome-icon icon="eye" />
                  </router-link>
                </td>
                <td class="tdBtnAcao">
                  <router-link
                    :to="{ 
                      name: 'Editar cliente',
                      params: { clienteId: item.id } 
                    }"
                    class="icon"
                  >
                    <font-awesome-icon icon="pen-to-square" />
                  </router-link>
                </td>
              </div> 
            </tr>
            <tr v-if="chamadasPendentes.lista">
              <td colspan="3">
                <font-awesome-icon
                  icon="spinner" 
                  class="spinner mt-3"
                />
                Carregando
              </td>
            </tr>
            <tr v-else-if="erro">
              <td colspan="3">
                Erro: {{ erro }}
              </td>
            </tr>
            <tr v-else-if="!lista.length">
              <td colspan="3">
                Nenhum resultado encontrado.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="paginacao" 
        class="d-flex gap-2 justify-content-center mt-4"
      >
        <button
          v-for="(link, index) in paginacao.links.slice(1, -1)"
          :key="index"
          class="paginacao-btn"
          :disabled="!link.url"
          :class="link.active ? 'paginacaoAtiva' : ''"
          @click="mudarPagina(link)"
        >
          {{ link.label }}
        </button>     
      </div>
    </div>

    <BoletosLista/>
  </div>
</template>

<script setup>

import simNao from '@/consts/simNao';
import tipoNegocio from '@/consts/tipoNegocio';
import { useClientesStore } from '@/store/clientes.store';
import { usePlanoStore } from '@/store/plano.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BoletosLista from './BoletosLista.vue';

const planoStore = usePlanoStore()
const { planosRegulares } = storeToRefs(planoStore);

async function iniciar(){
 await planoStore.buscarTudo()
}
iniciar()


const route = useRoute();
const router = useRouter();

const clientesStore = useClientesStore()
const { lista, chamadasPendentes, erro, paginacao } = storeToRefs(clientesStore);

clientesStore.buscarTudo()

const name = ref(route.query.name)
const cpf = ref(route.query.cpf)
const city = ref(route.query.city)
const active = ref(route.query.active
? Object.keys(simNao)
  .find((x) => x.toLowerCase() === route.query.active.toLocaleLowerCase())
: undefined);
const pagantes = ref(route.query.pagantes
? Object.keys(simNao)
  .find((x) => x.toLowerCase() === route.query.pagantes.toLocaleLowerCase())
: undefined);

const plano = ref(route.query.plano
? Object.keys(planosRegulares.value)
  .find((x) => x.toLowerCase() === route.query.plano.toLocaleLowerCase())
: undefined);

const consulta = {};
function atualizarUrl() {

  consulta.name = name.value || ' ';
  consulta.cpf = cpf.value || ' ';
  consulta.city = city.value || ' ';
  consulta.active = active.value || ' ';
  consulta.plano = plano.value || ' ';
  consulta.pagantes = pagantes.value || ' ';

  router.push({
    query: consulta,
  });
}

watch([
  () => route.query.name,
  () => route.query.cpf,
  () => route.query.city,
  () => route.query.active,
  () => route.query.plano,
  () => route.query.pagantes,
], () => {
  
  const consultaAtualizada = {
    name: route.query.name || '', 
    cpf: route.query.cpf || '',
    city: route.query.city || '',
    active: route.query.active || '',
    plano: route.query.plano || '',
    pagantes: route.query.pagantes || '',
  };

  clientesStore.$reset();
  clientesStore.buscarTudo(consultaAtualizada);
}, { immediate: true });

function limparFiltro(){
  name.value = '';
  cpf.value = '';
  city.value = '';
  active.value = '';
  plano.value = '';
  pagantes.value = '';

  router.push({
    query: {}
  });

  clientesStore.$reset();
  clientesStore.buscarTudo({});
}

async function mudarPagina(link) {
  if (link){
    await clientesStore.mudarPagina(link.label)
  }
}
</script>

<style scoped>
.name, 
.email{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.endereco{
  max-width: 230px;
}

.tipo{
  max-width: 150px;
}
</style>