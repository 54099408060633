<template>
  <section class="card-branco">
    <router-link
      to="/clientes"
      class="btnVoltar"
    >
      <font-awesome-icon
        icon="circle-left"
      />
    </router-link>
    <section>
      <div class="py-3 px-3 bordaAmarela mb-3">
        <p>
          Olá Usuário! É muito importante a inserção dos seus dados,
          pois eles farão parte de todo o trabalho realizado na ferramenta e também
          para contato da nossa equipe com você. Tenha uma ótima experiência!
        </p>
      </div>
    </section>
    <form
      @submit.prevent="onSubmitAtualizarPerfil"
    >
      <div class="mb-3">
        <LabelFromYup 
          name="name" 
          :schema="schema"
          :required="true"
        />
        <Field
          name="name"
          placeholder="Seu nome"
          type="text"
          class="inputtext"
          :class="{ 'error': errors.name }"
        />
        <ErrorMessage
          class="error-msg"
          name="name"
        />
      </div>

      <div class="form-group w-100 my-2">
        <label>
          <Field
            name="tipo_pessoa"
            type="radio"
            value="Pessoa Física"
            class="inputcheckbox"
          />
          <span class="ps-2">Pessoa Física (Corretor)</span>
        </label>
        <label>
          <Field
            name="tipo_pessoa"
            type="radio"
            value="Pessoa Jurídica"
            class="inputcheckbox"
          />
          <span class="ps-2">Pessoa Jurídica(Imobiliária)</span>
        </label>
      </div>

      <div class="form-group w-100">
        <div class="mb-3">
          <LabelFromYup 
            name="cpf" 
            :schema="schema"
            :required="true"
          />
          <Field 
            v-maska
            name="cpf"
            type="text"
            placeholder="CPF/CNPJ"
            class="inputtext"
            maxlength="18"
            data-maska="['###.###.###-##', '##.###.###/####-##']"
            :class="{ 'error': errors.cpf }"
          />
          <ErrorMessage
            class="error-msg"
            name="cpf"
          />
        </div>

        <div class="mb-3">
          <LabelFromYup 
            name="rg" 
            :schema="schema"
            :required="true"
          />
          <Field 
            v-maska
            name="rg"
            type="text"
            placeholder="RG"
            class="inputtext mb-2"
            maxlength="15"
            :class="{ 'error': errors.rg }"
          />
          <ErrorMessage
            class="error-msg"
            name="rg"
          />
        </div>
      </div>

      <div class="form-group w-100">
        <div class="form-group w-100">
          <div class="mb-3">
            <LabelFromYup
              name="nascimento"
              :schema="schema"
              :required="true"
            />
            <Field
              name="nascimento"
              type="date"
              class="inputtext light mb1"
              :class="{ 'error': errors.nascimento }"
              maxlength="10"
            />
            <ErrorMessage
              name="nascimento"
              class="error-msg"
            />
          </div>

          
          <div class="mb-3">
            <LabelFromYup
              name="sexo"
              :schema="schema"
              :required="true"
            />
            <Field
              name="sexo"
              as="select"
              class="inputtext light mb1"
              :class="{ 'error': errors.sexo }"
              @change="setFieldValue('profissao', '')"
            >
              <option value="">
                Selecionar
              </option>
              <option
                v-for="item in Object.values(genero)"
                :key="item.valor"
                :value="item.valor"
              >
                {{ item.valor }}
              </option>
            </Field>
            <ErrorMessage
              name="sexo"
              class="error-msg"
            />
          </div>
        </div>

        <div class="w-100">
          <div class="mb-3">
            <LabelFromYup
              name="profissao"
              :schema="schema"
              :required="true"
            />
            <Field
              name="profissao"
              as="select"
              class="inputtext"
              :class="{ 'error': errors.profissao }"
            >
              <option value="">
                Selecionar
              </option>
              <option 
                v-for="item in profissoes[clienteEmFoco?.sexo]" 
                :key="item.valor"
                :value="item.valor"
              >
                {{ item.valor }}
              </option>
            </Field>
            <ErrorMessage
              name="profissao"
              class="error-msg"
            />
          </div>
        </div>
      </div>

      <div class="form-group w-100">
        <div class="mb-3">
          <LabelFromYup 
            name="email" 
            :schema="schema"
            :required="true"
          />
          <Field
            name="email"
            placeholder="seu_email@provedor.com" 
            type="text" 
            class="inputtext"
            :class="{ 'error': errors.email }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="email"
          />
        </div>

        <div class="mb-3">
          <LabelFromYup 
            name="telefone_celular" 
            :schema="schema"
            :required="true"
          />
          <Field 
            v-maska
            name="telefone_celular"
            type="text"
            placeholder="(00) 00000-0000"
            class="inputtext"
            maxlength="15"
            data-maska="(##) #####-####"
            :class="{ 'error': errors.telefone_celular }"
          />
          <ErrorMessage
            class="error-msg"
            name="telefone_celular"
          />
        </div>
      </div>

      <div class="form-group w-100">
        <div class="mb-3">
          <LabelFromYup
            name="sexo"
            :schema="schema"
            :required="true"
          />
          <Field
            name="creci"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.creci }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(creciRegiao)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.valor }}
            </option>
          </Field>
          <ErrorMessage
            name="creci"
            class="error-msg"
          />
        </div>

        <div class="mb-3">
          <LabelFromYup 
            name="numero_creci" 
            :schema="schema"
          />
          <Field 
            name="numero_creci"
            type="text"
            placeholder="Número creci"
            class="inputtext"
          />
        </div>
      </div>

      <div class="form-group w-100">
        <div class="mb-3">
          <LabelFromYup 
            name="cnai" 
            :schema="schema"
          />
          <Field
            name="cnai"
            placeholder="CNAI" 
            type="text" 
            class="inputtext"
          />
        </div>

        <div class="form-group w-100">
          <div class="mb-3">
            <LabelFromYup 
              name="cor_perfil" 
              :schema="schema"
            />
            <Field 
              v-maska
              name="cor_perfil"
              type="color"
              class="discoCores"
            />
          </div>

          <div class="mb-3">
            <LabelFromYup 
              name="cor_text_perfil" 
              :schema="schema"
            />
            <Field 
              v-maska
              name="cor_text_perfil"
              type="color"
              class="discoCores"
            />
          </div>
        </div> 
      </div>

      <div class="d-flex w-100 gap-3">
        <div class="mb-3 d-flex align-items-end">
          <div>
            <LabelFromYup 
              name="cep"
              :schema="schema"
              :required="true"
            />
            <Field
              v-model="cep"
              v-maska
              name="cep"
              type="text"
              placeholder="CEP"
              class="inputtext"
              maxlength="9"
              data-maska="#####-###"
              :class="{ 'error': errors.cep }"
              @focusout="getCep"
            />
            <ErrorMessage
              class="error-msg"
              name="cep"
            />
          </div>
          <span v-if="chamadasPendentes.clienteEmFoco">
            <font-awesome-icon
              icon="spinner" 
              class="spinner mt-3"
            />
          </span>
        </div>

        <div class="mb-2 w-75">
          <LabelFromYup
            name="endereco" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.logradouro"
            name="endereco" 
            type="text" 
            placeholder="Rua" 
            class="inputtext light mb-2"
            :class="{ 'error': errors.endereco }"
          />
          <ErrorMessage 
            class="error-msg"
            name="endereco"
          />
        </div>
      </div>

      <div class="d-flex w-100 gap-3">
        <div class="mb-3 w-25">
          <LabelFromYup 
            name="numero" 
            :schema="schema"
            :required="true"
          />
          <Field 
            name="numero" 
            type="text" 
            placeholder="Número" 
            class="inputtext"
            :class="{ 'error': errors.numero }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="numero"
          />
        </div>

        <div class="mb-3 w-75">
          <LabelFromYup 
            name="bairro" 
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="lista.bairro"
            name="bairro" 
            type="text" 
            placeholder="Bairro" 
            class="inputtext"
            :class="{ 'error': errors.bairro }"
          />
          <ErrorMessage 
            class="error-msg"
            name="bairro"
          />
        </div>
      </div>

      <div class="mb-3 w-100">
        <LabelFromYup 
          name="complemento" 
          :schema="schema"
        />
        <Field 
          name="complemento"
          type="text" 
          placeholder="Complemento" 
          class="inputtext mb-2"
        />
      </div>

      <div class="form-group w-100">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="cidade" 
            :schema="schema"
          />
          <Field
            v-model="lista.localidade"
            name="cidade" 
            type="text" 
            placeholder="cidade" 
            class="inputtext"
            :class="{ 'error': errors.cidade }"
          />
          <ErrorMessage 
            class="error-msg" 
            name="cidade"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="estado" 
            :schema="schema"
          />
          <Field 
            v-model="lista.uf"
            name="estado" 
            type="text" 
            placeholder="Estado" 
            class="inputtext light mb-2"
            :class="{ 'error': errors.estado }"
          />
          <ErrorMessage 
            class="error-msg"
            name="estado"
          />
        </div>
      </div>

      <div class="mb-2 w-100">
        <label 
          for="tabela_honorarios"
          name="tabela_honorarios"
        >
          Tabela de Honorários
        </label>
        <input
          id="tabela_honorarios"
          name="tabela_honorarios"
          type="file" 
          class="inputtext"
          @change="onFileChange"
        >
      </div>

      <div>
        <div>
          <label style="color: var(--roxo)">
            <strong>Currículo</strong>
          </label>
          <QuillEditor 
            v-model:content="curriculum"
            content-type="html"
            theme="snow"
            class="mb-3"
          />   
        </div>

        <div>
          <label style="color: var(--roxo)">
            <strong>Histórico imobiliário</strong>
          </label>
          <QuillEditor 
            v-model:content="historico_imobiliario"
            content-type="html"
            theme="snow"
            class="mb-3"
          />     
        </div>
      </div>


      <div class="d-flex flex-column align-items-center mt-4">
        <button 
          class="btnRoxo" 
          :disabled="isSubmitting"
        >
          Salvar
        </button>
        <font-awesome-icon 
          v-show="isSubmitting" 
          icon="spinner" 
          class="spinner mt-3"
        />
      </div>
    </Form>
  </section>
</template>

<script setup>
import creciRegiao from '@/consts/creciRegiao';
import {
  novaSenhaPerfil as novaSenhaPerfilSchema,
  meuPerfil as schema
} from '@/consts/formSchema';
import genero from '@/consts/genero';
import profissoes from '@/consts/profissao';
import { useAlertStore } from '@/store/alert.store';

import { useCepStore } from '@/store/buscaCep.store';
import { useClientesStore } from '@/store/clientes.store';
import { vMaska } from "maska/vue";
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const alertStore = useAlertStore();

const cepGet = useCepStore()
const { lista, chamadasPendentes  } = storeToRefs(cepGet);

const meusClientesStore = useClientesStore()
const { clienteEmFoco } = storeToRefs(meusClientesStore);

const fileXml = ref(null)
const historico_imobiliario = ref('')
const curriculum = ref('')
const props = defineProps({
  clienteId: {
    type: Number,
    default: 0,
  },
});

const {
  errors, isSubmitting, setFieldValue, resetForm, values, handleSubmit,
} = useForm({
  initialValues: clienteEmFoco.value,
  validationSchema: schema,
});

async function getCep(cep) {
  let ceptetste = cep.target.value.replace(/\D/g, '')
  try {
    await cepGet.getCep(ceptetste);
  } catch (error) {
    alertStore.error(error);
  }
}

const onFileChange = (event) => {
  fileXml.value = event.target.files[0];
};

const onSubmitAtualizarPerfil = handleSubmit(async (values) => {
  try{
    const payload = {
      ...values,
      curriculum: curriculum.value || clienteEmFoco.value.curriculum,
      historico_imobiliario: historico_imobiliario.value || clienteEmFoco.value.historico_imobiliario,
    };

    const resposta = await meusClientesStore.atualizarPerfilUsuarios(payload, props.clienteId);
    if(resposta.status === 'Success'){
      alertStore.success('Perfil atualizado com sucesso!');
      meusClientesStore.$reset();
      meusClientesStore.buscarItem(props.clienteId)
    }
  } catch (erros){
    alertStore.error(erros.message); 
  }
})

async function iniciar (){
  if(props.clienteId){
    await meusClientesStore.buscarItem(props.clienteId)
  }
}
iniciar()

watch(clienteEmFoco, (novoValor) => {
  if (novoValor) {
    resetForm({ values: novoValor });
    curriculum.value = novoValor.curriculum || '';
    historico_imobiliario.value = novoValor.historico_imobiliario || '';
  }
}, { immediate: true });
</script>

<style scoped>
h2{
  color: var(--roxo);
  font-size: 20px;
  font-weight: 600;
}

.perfil{
  display: grid;
  grid-template-columns: 64% 34%;
  gap: 20px;
}
.discoCores {
  width: 52px;
  height: 52px;
  border: none;
  display: block;
}

.fa-user, .fa-image, .fa-file-lines {
  color: var(--branco);
  font-size: 68px;
}

.user {
  background: var(--cinza);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

.user img{
  border-radius: 50%;
  height: 100px;
  object-fit: cover;
}

.image {
  background: var(--cinza);
  height: 125px;
  border-radius: 12px;
}

.logo-assinatura{
  max-width: 200px;
  margin: auto;
}

.icon-content{
  border: 1px solid var(--roxo);
  border-radius: 7px;
  width: max-content;
  margin: auto;
  position: relative;
  top: -15px;
  background: var(--branco);
  padding: 2px 15px;
}

.labelFile{
  border: none;
  background: none;
  color: var(--cinza);
  font-size: 20px;
  padding-bottom: 0;
  cursor: pointer;
}

.ativo svg {
  background: initial; 
  color: var(--roxo); 
}
</style>