<template>
  <div class="card-branco w-100">
    <div class="d-flex justify-content-between align-items-center">
      <h1>Novas assinaturas</h1>
      <CButton 
        class="d-flex align-items-center ver-mais"
        @click="visible = !visible"
      >
        Ver mais 
        <font-awesome-icon 
          class="ps-2" 
          icon="circle-plus"
        />
      </CButton>
    </div>
    <CCollapse :visible="visible">
      <CCard class="my-3">
        <CCardBody class="p-0">
          <div class="tabela">
            <table class="w-100">
              <col>
              <col>
              <col>
              <col>
              <thead>
                <tr>
                  <th>Plano</th>
                  <th>Método de pagamento</th>
                  <th>Status</th>
                  <th>Expiração</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in props.listaAssinatuta"
                  :key="item.id"
                >
                  <td class="email">{{ item.plan_name }}</td>
                  <td class="email">{{ item.metodo_pagamento }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ dateToField(item.data_expiracao) }}</td>
                </tr>
                <tr v-if="!props.listaAssinatuta?.length">
                  <td colspan="3">
                    Nenhum resultado encontrado.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
      <router-link
        to="/usuarios"
        class="ver-mais"
      >
        Ver todos
      </router-link>
    </CCollapse>
  </div>
</template>



<script setup>
import dateToField from '@/helpers/dateToFiel';
import { ref } from 'vue';

const visible = ref(false)

const props = defineProps({
  listaAssinatuta:{
    type: Array,
    default: () => []
  }
})
</script>

<style scoped>
.email{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
}

.ver-mais{
  background-color: transparent;
  border: none;
  text-wrap: nowrap;
  color: var(--roxo);
  font-weight: 500;
}

h1 {
  color: var(--roxo);
}
</style>