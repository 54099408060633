export default {
  "paid": {
    nome: 'Aprovado',
    valor: 'paid',
  },
  "pending": {
    nome: 'Pendente',
    valor: 'pending',
  },
};
