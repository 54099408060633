import { defineStore } from 'pinia';

// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useHistoricoAcessosStore = defineStore('historicoAcessos', {
  state: () => ({
    historicoNavegacao: [],
    emFoco: null,
    chamadasPendentes: {
      historicoNavegacao: false,
      emFoco: false,
    },
    erro: null,
  }),
  actions: {
    async buscarTudo(id = 0) {
      this.chamadasPendentes.historicoNavegacao = true;
      this.erro = null;

      try {
        const request  = await this.requestS.get(`${baseUrl}/user/navigation-history/${id}`);
        this.historicoNavegacao =  request.navigationHistory
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.historicoNavegacao = false;
    },
  },
});
