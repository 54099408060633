<template>
  <div>
    <h1 class="mb-3">Boletos de assinantes</h1>
    <strong
      style="color: var(--roxo)"
    >
      Filtrar por:
    </strong>
    <form
      class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between" 
      @submit.prevent="atualizarUrl"
    >
      <div class="d-flex gap-3 align-items-center">
        <div>
          <label>Email</label>
          <input
            id="email"
            v-model.trim="email"
            placeholder="email"
            class="inputtext"
            name="email"
            type="text"
          >
        </div>

        <div>
          <label>Status</label>
          <select
            id="status"
            v-model.trim="status"
            class="inputtext"
            name="status"
          >
            <option 
              value=""
            />
            <option
              v-for="item in Object.values(statu)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex gap-3">
        <button @click="limparFiltro" type="button" class="btnLabel">
          <font-awesome-icon
            icon="trash"
          />
          Limpar
        </button>
        <button class="btnAmarelo">
          Pesquisar
        </button>
      </div>
    </form>
    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Plano</th>
            <th>Criado</th>
            <th>Valor</th>
            <th>Status</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="item in lista"
            :key="item.id"
          >
            <td class="name">{{ item.name }}</td>
            <td class="email">{{ item.email }}</td>
            <td>{{ item.plan_name ? item.plan_name : '-' }}</td> 
            <td>{{ dateToField(item.created_at ? item.created_at : '-')}}</td> 
            <td>R${{ dinheiro(item.amount)}}</td> 
            <td>{{ item.status === "pending" ? "Pendente" : "Aprovado" }}</td> 
          </tr>
          <tr v-if="chamadasPendentes.lista">
            <td colspan="3">
              <font-awesome-icon
                icon="spinner" 
                class="spinner mt-3"
              />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="3">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!lista.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-if="paginacao" 
      class="d-flex gap-2 justify-content-center mt-4"
    >
      <button
        v-for="(link, index) in paginacao.links.slice(1, -1)"
        :key="index"
        class="paginacao-btn"
        :disabled="!link.url"
        :class="link.active ? 'paginacaoAtiva' : ''"
        @click="mudarPagina(link)"
      >
        {{ link.label }}
      </button>     
    </div>
  </div>
</template>

<script setup>
import statu from '@/consts/status';
import dateToField from '@/helpers/dateToFiel';
import dinheiro from '@/helpers/dinheiro';
import { useBoletosStore } from '@/store/boletos.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const boletosStore = useBoletosStore()
const { lista, chamadasPendentes, erro, paginacao } = storeToRefs(boletosStore);

const email = ref(route.query.email)
const status = ref(route.query.status
? Object.keys(statu)
  .find((x) => x.toLowerCase() === route.query.status.toLocaleLowerCase())
: undefined);

const consulta = {};

function atualizarUrl() {
  consulta.email = email.value || '';
  consulta.status = status.value || '';

  router.push({
    query: consulta,
  });

  boletosStore.$reset();
  boletosStore.buscarTudo(consulta);
}

watch([
  () => route.query.email,
  () => route.query.status,
], () => {
  const consultaAtualizada = {
    email: route.query.email || '',
    status: route.query.status || ''
  };
  boletosStore.$reset();
  boletosStore.buscarTudo(consultaAtualizada);

}, { immediate: true });

function limparFiltro(){
  email.value = '';
  status.value = '';

  router.push({
    query: {}
  });

  boletosStore.$reset();
  boletosStore.buscarTudo({});
}

async function mudarPagina(link) {
  if (link){
    await boletosStore.mudarPagina(link.label)
  }
}

async function iniciar() {
  await boletosStore.buscarTudo()
  await boletosStore.buscarTudo()
}
iniciar()
</script>

<style scoped>
.name, 
.email{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.endereco{
  max-width: 230px;
}

.tipo{
  max-width: 150px;
}
</style>