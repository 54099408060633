<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>Minhas pesquisas</h1>
      <router-link
        :to="{ path: '/pesquisas/novaPesquisa' }"
        class="btnRoxo"
      >
        Nova pesquisa
      </router-link>
    </div>
    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Título</th>
            <th>Descrição</th>
            <th>Status</th>
            <th>Obrigatória</th>
            <th>Criada</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <template v-for="item in lista" :key="item.id">
            <tr>
              <td>{{ item.title }}</td>
              <td class="descricao">{{ limparHTML(item.description)}}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.required ? "Sim" : "Não"}}</td>
              <td>{{ dateToField(item.created_at) }}</td>
              <td class="tdBtnAcao">
                <router-link
                  :to="{ 
                    name: 'Editar pesquisa',
                    params: { pesquisaId: item.id } 
                  }"
                  class="icon pe-2"
                >
                  <font-awesome-icon icon="pen-to-square" />
                </router-link>
              </td>
              <td class="tdBtnAcao">
                <router-link
                  :to="{ 
                    name: 'Resposta pesquisa',
                    params: { pesquisaId: item.id } 
                  }"
                  class="icon"
                >
                  <font-awesome-icon icon="fa-solid fa-file-circle-question" />
                </router-link>
              </td>
            </tr>
          </template>
          <tr v-if="chamadasPendentes.lista">
            <td colspan="6">
              <font-awesome-icon icon="spinner" class="spinner mt-3" />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="6">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!lista.length">
            <td colspan="6">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import dateToField from '@/helpers/dateToFiel';
import { usePesquisaStore } from '@/store/pesquisas.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const PesquisaStore = usePesquisaStore()
const { lista, chamadasPendentes, erro } = storeToRefs(PesquisaStore);

function limparHTML(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
}

async function iniciar(){
  await PesquisaStore.buscarTudo()
}
iniciar()

</script>

<style scoped>
.descricao{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 175px;
}
</style>